const en = {
  "global": {
    "language": "English",
    "not_found_title": "Page not found.",
    "not_found_tips": "This may be caused by a path error.",
    "login_greeting_morning": "Good Morning!",
    "login_greeting_afternoon": "Good Afternoon!",
    "login_greeting_evening": "Good Evening!",
    "login_password_title": "Password",
    "login_password_holder": "Please enter password.",
    "login_password_error": "The password should be 4-20 digits.",
    "login_email_holder": "Please enter email address.",
    "login_email_error": "Please confirm your email address.",
    "login_code_title": "Code",
    "login_qrcode_title": "QRCode",
    "login_qrcode_tips": "Please scan the code with {0}.",
    "login_qrcode_feishu": "Feishu App",
    "login_qrcode_new_tips": "Please scan the code with Feishu.",
    "login_button": "Log in",
    "login_forgot": "Forgot password",
    "login_logout": "Log out",
    "modal_cancel": "Cancel",
    "modal_delete": "Delete",
    "modal_create": "Create",
    "modal_save": "Save",
    "modal_confirm": "Please confirm your action.",
    "btn_add": "Add",
    "btn_change": "Update",
    "btn_delete": "Delete",
    "btn_save": "Save",
    "btn_confirm": "Confirm",
    "message_success_create": "Create success!",
    "message_error_create": "Create failure!",
    "message_success_update": "Update success!",
    "message_error_update": "Update failure!",
    "message_success_delete": "Delete success!",
    "message_error_delete": "Delete failure!",
    "message_success_upload": "Upload success!",
    "message_error_upload": "Upload failure!",
    "message_no_token": "Token expires, please login again!",
    "message_error_login_code": "Login error, please contact the development department.",
    "message_no_permission": "Permission denied!",
    "upload_file_format": "Supported formats:",
    "upload_file_size": "Maximum file size:",
    "form_holder_require": "Please enter it here!",
    "unselected_creator_tips": "No data, please select creator on the left first",
    "platform_hao_kan": "Haokan Video",
    "platform_tencent": "Tencent",
    "platform_iqiyi": "iQIYI",
    "platform_bilibili": "bilibili ",
    "platform_xi_gua": "Xigua video",
    "platform_wei_bo": "weibo",
    "platform_youtube": "Youtube",
    "platform_dou_yin": "Douyin",
    "platform_red_book": "Xiaohongshu",
    "platform_kuai_shou": "Kuaishou",
    "platform_cloud_music": "Netease cloud music",
    "platform_wechat_video": "Weixin Channel",
    "platform_mei_pai": "Meipai",
    "platform_da_yu": "Dayu",
    "platform_dian_tao": "Diantao",
    "platform_qq_world": "QQ shorts",
    "platform_tiktok": "TikTok",
    "platform_instagram": "Instagram",
    "platform_twitter": "Twitter",
    "language_en": "English",
    "language_zh": "Chinese",
    "language_ko": "Korean"
  },
  "member": {
    "create_time": "Create time",
    "name": "Account name",
    "member_type": "Plan level",
    "present_months": "Number of Months Presented",
    "validity_period": "Validity",
    "remark": "Remarks",
    "new_gift": "Additional benefits",
    "reset": "Reset",
    "search": "Serch",
    "member_benefits": "Member benefits",
    "present_uid": "Enter UID",
    "close": "close",
    "not_free": "User plan level is not FREE",
    "please_enter": "Please enter",
    "please_choose": "Please select",
    "add_new_present": "Add new present",
    "split": "You can enter it in batches through commas or spaces.",
    "month": "{0} Months",
    "create_success": "Success",
    "create_fail": "Failures",
    "get_list_fail": "Failed to get list data",
    "reviewer": "Operator"
  },
  "audit": {
    "platform_review": "Platform review",
    "content_review": "Content moderation",
    "operation": "operate",
    "op_success": "Successful operation",
    "op_fail": "operation failed",
    "confirm_delete": "Are you sure the reason for failure to delete this item?",
    "add_child": "Add new subitem",
    "add_root": "Add a new item",
    "template_table": "Error template form",
    "save_error": "There is some content that has not been filled in and the information cannot be saved.",
    "audit_edit": "Audit configuration",
    "qrcode_review": "Verification Code Review"
  },
  "enterprise": {
    "enterprise_name": "Company Name",
    "business_license": "Business Registration Number",
    "member_number": "Number of members",
    "register_status": "Registration Status",
    "operation_status": "Operation Status",
    "create_time": "Created on",
    "register_time": "Registered on",
    "creator": "Operator",
    "new_enterprise": "New Enterprise",
    "create_new_account": "Register New Enterprise Account",
    "enterprise_management": "Enterprise Management",
    "operation_status_inactivity": "Deactivated",
    "operation_status_activity": "Activated",
    "create_success": "Created successfully",
    "create_fail": "Failed to create",
    "invite_code": "Referral code",
    "operation": "Operation",
    "check": "View",
    "detail_page": "View detailed information",
    "get_basic_info_fail": "Failed to obtain basic information of the company",
    "get_renew_info_fail": "Failed to obtain renewal information of the company",
    "email": "Email",
    "paypal": "PayPal account",
    "paypal_order_number": "PayPal order number",
    "basic_information": "Basic information",
    "renew_management": "Renewal management",
    "renew_handle": "Renewal processing",
    "paid_time": "Payment date",
    "handle": "Processing",
    "renew_status": "Payment status",
    "money": "Payable amount",
    "people": "Number of members on D-7",
    "end_time": "Scheduled payment date",
    "countdown_time": "D-7",
    "unpaid": "Not paid",
    "paid": "Paid",
    "handle_success": "Processed successfully",
    "handle_fail": "Processing failed",
    "approve": "Appove",
    "approve_time": "Appoved at",
    "confirm_approve": "Approve request?",
    "confirm_approve_success": "Approved",
    "confirm_approve_fail": "Approval failed"
  },
  "dashboard": {
    "work_bench": "Dashboard",
    "account_manage": "Account Management",
    "road_manage": "User Manage",
    "data_analysis": "Data Analysis",
    "income_manage": "Revenue management",
    "order_manage": "Business Order Management",
    "personal_center": "Personal Center",
    "feedback": "Feedback",
    "experimental_center": "Experimental Center",
    "close_sidebar": "Close the sidebar",
    "translation_manage": "AI Subtitle",
    "notice_manage": "Notification"
  },
  "account": {
    "search_placeholder": "Search adoba ID/Channel name/Phone number",
    "export_operation_info": "Export Operation Info",
    "add_new_account": "Add New Account",
    "creator_list_all": "All",
    "creator_list_fav": "Interest",
    "creator_list_manage": "Manage",
    "creator_list_sort_fans": "Subscribers",
    "creator_list_sort_views": "Views",
    "creator_list_sort_uprate": "Growth Rate",
    "header_no": "no.",
    "header_country": "Country",
    "header_contract_end": "Contract End",
    "header_cate": "Category",
    "header_type": "Type",
    "header_expired": "Expired",
    "header_days": "Days",
    "header_operate_status": "Operation Status",
    "header_personal_Info": "Personal Info",
    "card_operate_platform": "Operation Platform",
    "card_operate_log": "Operation Log",
    "card_operate_info": "Operation Info",
    "card_business_info": "Business Info",
    "card_base_info": "Creator Base Info",
    "card_foreign_info": "Overseas Platform Info",
    "card_passport_info": "Passport Info",
    "card_contract_info": "Contract Info",
    "card_bank_info": "Bank Info",
    "platform_is_bind": "Bound",
    "platform_un_bind": "Unbound",
    "platform_fans": "Fans",
    "platform_add": "Add Operation Platform",
    "platform_name": "Platform Name",
    "platform_account_name": "Account Name",
    "platform_url": "URL",
    "platform_uid": "UID",
    "platform_start_date": "First Upload ",
    "platform_bind_phone": "Phone Number",
    "platform_bind_email": "Email",
    "platform_mcn_bind": "MCN",
    "platform_withdrawal": "Withdrawal",
    "platform_un_authorize": "Unauthorized",
    "platform_is_authorize": "Authorized",
    "platform_main_page": "Home Page",
    "platform_password": "Password",
    "platform_pid": "PID",
    "platform_tao_bao": "Taobao union",
    "platform_JD": "Jingdong union",
    "platform_pin_duo_duo": "Pinduoduo",
    "platform_mo_gu": "Mogu",
    "platform_not_begun": "Not Begun",
    "platform_not_started": "Not Started",
    "platform_change_title": "Change platform info",
    "platform_holder_name": "Select Platform Name",
    "platform_holder_account_name": "Input Account Name",
    "platform_holder_url": "Input URL",
    "platform_holder_uid": "Input UID",
    "platform_holder_start_date": "Select Date",
    "platform_holder_bind_phone": "Input Bind Phone Number",
    "platform_holder_bind_email": "Input Bind Email",
    "platform_holder_mcn_bind": "Select MCN Bind Status",
    "platform_holder_withdrawal": "Select Withdrawal Status",
    "platform_holder_join_page": "Open the Page",
    "platform_holder_password": "Input password",
    "platform_holder_pid": "Input PID",
    "platform_modal_confirm": "Please confirm your action.",
    "platform_check_code": "Please select country code!",
    "platform_check_email": "Email format error!",
    "platform_check_phone": "Phone format error!",
    "platform_check_password": "The password should be 6-16 digits.",
    "operate_status_operating": "Operating",
    "operate_status_cancelled": "Terminated",
    "operate_status_unsigned": "Unsigned",
    "operate_status_unprocessed": "Unprocessed",
    "contract_type_personal": "Personal",
    "contract_type_enterprise": "Enterprise",
    "contract_type_mcn": "MCN",
    "operate_head": "Manager",
    "operate_docking": "Docking",
    "operate_start_date": "Operation Start",
    "operate_start_day": "Days",
    "operate_not_begun": "Not Begun",
    "operate_frequency": "Update Frequency",
    "operate_frequency_week": "Week",
    "operate_frequency_month": "Month",
    "operate_frequency_times": "Updates",
    "operate_note": "Update Note",
    "operate_delay": "Delay or Not",
    "operate_delay_can": "Delay",
    "operate_delay_not": "Undelay",
    "operate_subtitles": "Subtitles",
    "operate_order": "Order",
    "operate_order_can": "Take",
    "operate_order_not": "Reject",
    "operate_cooperate": "Cooperation Degree",
    "operate_cover": "Cover",
    "operate_cover_creator": "Creator ",
    "operate_cover_self": "adoba",
    "operate_source": "Source",
    "operate_source_creator": "Creator",
    "operate_source_self": "adoba",
    "operate_major": "Attention",
    "operate_change_title": "Change Info",
    "operate_add_title": "Add Info",
    "operate_creator_self": "Creator Operation",
    "operate_repeat_error": "The docking and the manager cannot be the same!",
    "operate_holder_head": "Select Manager",
    "operate_holder_docking": "Select Docking",
    "operate_holder_start_date": "Select Date",
    "operate_holder_frequency": "Input number",
    "operate_holder_number": "Input number",
    "operate_holder_note": "Input note",
    "operate_holder_delay": "Select delay",
    "operate_holder_subtitles": "Input subtitles problem",
    "operate_holder_order": "Select order status",
    "operate_holder_cover": "Select cover status",
    "operate_holder_source": "Select video source",
    "operate_holder_major": "Input Notices",
    "operate_check_frequency": "Please select frequency type!",
    "person_base_check_frequency": "Please input frequency, must be numbers greater than 0 or 0!",
    "person_base_check_times": "Please input times, must be numbers greater than 0 or 0!",
    "business_price": "Reference Price",
    "business_add": "Add",
    "business_add_title": "Add Business Info",
    "business_change_title": "Change Business Info",
    "business_name": "Platform Name",
    "business_implant": "Implant Video",
    "business_custom": "Custom Video",
    "business_dynamic": "Dynamic Price",
    "business_forward": "Forward Price",
    "business_holder_name": "Input platform name",
    "business_holder_implant": "Input implant video price",
    "business_holder_custom": "Input custom video price",
    "business_holder_dynamic": "Input dynamic price",
    "business_holder_forward": "Input forward price",
    "log_update_content": "{3} of {1}{2} in Creator {0} has been modified from {4} to {5}",
    "log_update_pid_up": "The '{3}', {4} of {1}{2} in Creator {0} has been modified from {5} to {6}",
    "log_update_pid_add_del": "{1}{2} in Creator {0}, {5} of {3}'{4}'",
    "log_create_delete_content": "{2} in {1} of Creator {0} ",
    "log_create_content": "{1} for Creator {0}",
    "person_base_info": "Basic Info",
    "person_base_avatar": "Profile Pic",
    "person_base_nickname": "Nickname",
    "person_base_adoba_id": "adoba ID",
    "person_base_nation": "Nationality",
    "person_base_phone": "Phone",
    "person_base_email": "Email",
    "person_base_cate": "Category",
    "person_base_intro": "Intro",
    "person_base_add": "Add New Creator",
    "person_base_add_tip": "*Only users with administrator rights can add or modify",
    "person_message_success": "Success!",
    "person_message_error": "Error!",
    "person_message_error_details": "Nickname or adobaID already exists!",
    "person_base_modal_title": "Change Basic Info",
    "person_base_holder_avatar": "Upload profile pic",
    "person_base_holder_nickname": "Nickname is unique",
    "person_base_holder_id": "adoba ID is unique",
    "person_base_holder_nation": "Please select the nationality",
    "person_base_holder_phone": "Phone number is unique",
    "person_base_holder_email": "Please enter the email",
    "person_base_holder_cate": "Please select the category",
    "person_base_holder_intro": "Please inter the intro",
    "person_base_check_code": "Please select country code!",
    "person_base_check_id": "adoba ID format error!",
    "person_base_check_phone": "Phone format error!",
    "person_base_check_email": "Email format error!",
    "person_base_exist_nickname": "Nickname already exist!",
    "person_base_exist_id": "adoba ID already exist!",
    "person_base_exist_phone": "Phone already exist!",
    "person_overseas_platform": "Platform name",
    "person_overseas_name": "Account name",
    "person_overseas_url": "Main page",
    "person_overseas_uid": "UID",
    "person_overseas_modal_title": "Change Overseas Info",
    "person_overseas_holder_platform": "Please select platform",
    "person_overseas_holder_name": "Please enter the overseas account name",
    "person_overseas_holder_url": "Please enter the home page link",
    "person_overseas_holder_uid": "Please enter the UID",
    "person_overseas_check_uid": "Please enter the number",
    "person_privacy_info": "Privacy information",
    "person_passport_no": "Passport no.",
    "person_passport_name": "Passport name",
    "person_passport_expire": "Passport validity date",
    "person_passport_birth": "birthday",
    "person_passport_address": "address",
    "person_passport_modal_title": "Modify passport Information",
    "person_passport_holder_no": "Please enter your passport number",
    "person_passport_holder_name": "Please enter your passport name",
    "person_passport_holder_expire": "Please select the date of validity of your passport",
    "person_passport_holder_birth": "Please choose your birthday",
    "person_passport_holder_address": "Please enter your address",
    "person_contract_no": "Contract no.",
    "person_contract_cate": "classification",
    "person_contract_date": "Date of commencement and termination of contract",
    "person_contract_start_date": "Effective date",
    "person_contract_expire_date": "Expired Date",
    "person_contract_operate": "Exclusive operation",
    "person_contract_operate_true": "Yes",
    "person_contract_operate_false": "No",
    "person_contract_operate_ratio": "Ratio of operating income",
    "person_contract_ad": "Exclusive business",
    "person_contract_ad_ratio": "Proportion of business income",
    "person_contract_remark": "Remarks related to contract",
    "person_contract_modal_title": "Modifying contract Information",
    "person_contract_remove": "Terminated",
    "person_contract_remove_date_true": "Yes",
    "person_contract_not_operating": "Unoperated",
    "person_contract_not_operating_true": "Yes",
    "person_contract_remove_date": "Termination date",
    "person_contract_not_operating_date": "Unoperated date",
    "person_contract_true": "Yes",
    "person_contract_false": "No",
    "person_contract_holder_no": "Please enter the Contract no.",
    "person_contract_holder_cate": "Please select contract classification",
    "person_contract_holder_operate": "Exclusive operation",
    "person_contract_holder_operate_ratio": "Revenue ratio, please enter the number from 0 to 100",
    "person_contract_holder_ad": "Please select exclusive business",
    "person_contract_holder_ad_ratio": "Business ratio, please enter the number from 0 to 100",
    "person_contract_holder_remove_date": "Please select termination date",
    "person_contract_holder_not_operating_date": "Please select unoperated date",
    "person_contract_holder_remark": "Please enter remarks related to the contract",
    "person_contract_check_operate": "Format error!",
    "person_contract_check_ad_ratio": "Format error!",
    "person_bank_nation": "Bank location",
    "person_bank_name": "Bank name",
    "person_bank_account": "bank account",
    "person_bank_address": "Bank Address (English ver.)",
    "person_bank_swift_code": "bank Swift Code",
    "person_bank_user_name": "name",
    "person_bank_id_card": "ID",
    "person_bank_modal_title": "Modifying bank information",
    "person_bank_holder_nation": "Please select bank location",
    "person_bank_holder_name": "Please enter the bank name",
    "person_bank_holder_account": "Please enter the bank account",
    "person_bank_holder_address": "Please enter the Bank address in English",
    "person_bank_holder_swift_code": "Please enter bank Swift Code",
    "person_bank_holder_user_name": "Please enter your name",
    "person_bank_holder_id_card": "Please input ID no.",
    "passport_dashboard_title": "Scanned Image of Signed Passport",
    "passport_handhold_title": "Selfie with Your Signed Passport",
    "person_level": "User level",
    "account_type": "Account Type",
    "bind_mcn": "Bind MCN"
  },
  "feedback": {
    "feedback_type": "Feedback type",
    "type_all": "All",
    "type_login": "Login problem",
    "type_app": "App problem",
    "type_suggest": "Proposal",
    "type_other": "Other"
  },
  "road": {
    "search_placeholder": "Search UID/Channel name/Email",
    "creator_list_applied": "Applied",
    "creator_list_open_manage": "Open application",
    "creator_list_all": "All",
    "creator_status_cancelled": "Cancelled",
    "check_identity": "Check Data",
    "header_notice": "Email notification",
    "header_notice_open": "Agree",
    "header_notice_close": "DisAgree",
    "identity_info": "Data Info",
    "passport_info": "Passport Info",
    "overseas_info": "Overseas Platform Info",
    "account_info": "Operation Info",
    "info_confirm": "Confirm Info",
    "account_avatar": "Profile Pic",
    "account_name": "Account Name",
    "account_name_holder": "Input Account Name",
    "account_introduction": "Introduction",
    "account_introduction_holder": "Please inter the intro",
    "account_type": "Category",
    "account_type_holder": "Please select the category",
    "account_phone": "Register Phone",
    "account_phone_holder": "Input Register Phone Number",
    "account_email": "Email",
    "account_email_holder": "Input Email",
    "platform_case": "Platform Case",
    "overseas_platform": "Overseas Platform",
    "overseas_platform_holder": "Select Overseas Platform",
    "overseas_link": "Overseas Platform Link",
    "overseas_link_holder": "Input overseas platform link",
    "overseas_link_error": "The link to the overseas platform is incorrect and must start with http or https",
    "overseas_link_method": "How to get links to overseas platforms",
    "overseas_link_method_tips": "On your overseas platform home page, copy the current page link",
    "overseas_method": "Detailed Obtaining Method",
    "overseas_nickname": "Overseas Platform Nickname",
    "overseas_nickname_holder": "Input Overseas Platform Nickname",
    "overseas_upload_proof": "Upload proof materials",
    "overseas_main_page": "Homepage Screenshot",
    "overseas_upload_main_page": "Please upload screenshots of your home page on your mobile phone or computer",
    "overseas_main_page_tips": "Please take the following screenshot",
    "overseas_backstage": "Platform backstage and myself",
    "overseas_upload_backstage": "Please upload the platform backstage and take a photo with me",
    "overseas_backstage_tips": "Please take photos according to the following example images",
    "personal_passport_name": "Passport Name",
    "personal_passport_name_holder": "Input Passport Name",
    "personal_passport_number": "Passport Number",
    "personal_passport_number_holder": "Input Passport Number",
    "personal_passport_date": "Validity Date",
    "personal_passport_date_holder": "Select Validity Date",
    "personal_passport_date_error": "The passport should not be valid for less than 6 months",
    "personal_upload_proof": "Upload proof materials",
    "personal_upload_passport": "Passport scanned copy",
    "personal_upload_passport_holder": "Please upload a scanned copy of your passport",
    "personal_upload_passport_tips": "Please take photos according to the following example images",
    "personal_method": "Detailed obtaining method",
    "personal_passport_hold": "Holding passport photo",
    "personal_passport_hold_holder": "Please upload your hand-held passport photo",
    "personal_passport_hold_tips": "Please take photos according to the following example images",
    "personal_passport_success": "Passport information updated successfully",
    "personal_passport_fail": "Failed to update passport information",
    "personal_oversea_success": "Information on overseas platforms has been updated successfully",
    "personal_oversea_fail": "Failed to update information on overseas platform",
    "confirm_info": "Confirm the information",
    "submit_material_success": "Platform documents are submitted successfully",
    "submit_material_fail": "Platform data submission failed",
    "header_title": "Open application",
    "header_no": "UID",
    "header_cate": "Category",
    "table_platform": "Platform",
    "table_apply_date": "Application date",
    "table_off_site_problem": "Outside",
    "table_data_problem": "Data",
    "table_confirm_problem": "Certification",
    "table_finished": "Finish",
    "table_finished_date": "End date",
    "table_account_name": "Account Name",
    "table_phone": "Phone",
    "table_email": "Email",
    "table_password": "Password",
    "table_action": "Action",
    "progress_not_start": "Not Started",
    "progress_ongoing": "Ongoing",
    "progress_completed": "Completed",
    "progress_open_completed": "Completed",
    "progress_open_failed": "Failed",
    "progress_modal_title": "Opening Progress",
    "table_apply_date_remaining": "D-{0}day",
    "table_apply_date_expire": "D+{0}day",
    "table_modal_steps_tips": "You need to enable the data tracking function to complete the operation.",
    "table_modal_steps_to_open": "To open.",
    "table_modal_password_holder": "Input Password",
    "table_modal_note": "note",
    "table_modal_note_holder": "Input Note",
    "modal_message_err_status": "Failed to update status!",
    "modal_message_err_password": "Failed to update password!",
    "modal_message_err_tracking": "Please enable data tracking!",
    "platform_account_name_error": "You can enter only Chinese, English, and numbers",
    "platform_is_stopped": "Data Tracking",
    "platform_is_stopped_confirm": "Important, please confirm again",
    "platform_is_stopped_must_read": "Must read",
    "modal_footer_previous": "Schedule",
    "modal_footer_previous_btn": "Previous",
    "all_platform_modal_tips_attention": "Attention",
    "all_platform_modal_tips_link": "The home link should be filled in correctly.",
    "all_platform_modal_tips_mcn": "The MCN must be bound.",
    "all_platform_modal_tips_nonsupport": "XiaoHongshu platforms do not support data tracking for the time being.",
    "all_platform_modal_tips_times": "The interval of each operation is 6 hours.",
    "all_platform_modal_tips_ failure": "Wait 5-10 minutes to know the tracking result, tracking failure will automatically shut down, you need to ask the development department for processing.",
    "data_tracking_confirm_open_title": "Are you sure to turn on data tracking?",
    "data_tracking_confirm_close_title": "Are you sure to turn off data tracking?",
    "data_tracking_countdown_label": "Next operation",
    "data_list_count": "A total of {} data"
  },
  "income": {
    "header_view_market_btn": "Data Market",
    "header_export_settlement_btn": "Export Settlement Data",
    "header_tax_manage_btn": "Tax Manage",
    "tax_manage_modal_title": "Tax Manage",
    "tax_manage_tax_item": "Tax Item {0}",
    "tax_manage_name": "Tax name",
    "tax_manage_rate": "Tax ratio",
    "tax_manage_text": "Tax explain",
    "tax_manage_name_holder": "Input tax name",
    "tax_manage_rate_holder": "Input tax ratio",
    "tax_manage_text_holder": "Input tax explain",
    "export_settlement_file_name": "Settlement Info",
    "export_settlement_title_tips": "Export all by default",
    "export_settlement_date_range": "Date",
    "export_settlement_status": "Type",
    "export_settlement_date_start_holder": "Select a start date",
    "export_settlement_date_end_holder": "Select a end date",
    "export_settlement_status_holder": "Select export type",
    "creator_list_tabs_apply": "Apply",
    "creator_list_tabs_applied": "Applied",
    "creator_list_tabs_reviewed": "Reviewed",
    "creator_list_tabs_transfered": "Transered",
    "creator_list_tabs_rejected": "Rejected",
    "creator_list_tabs_failed": "Failed",
    "creator_list_tabs_all": "All",
    "market_income_title": "Revenue Market",
    "market_income_subtitle": "The data of the previous day shall prevail.",
    "market_income_all_income": "Total platform revenue",
    "market_income_all_opens": "Total platform opening number",
    "market_income_all_opens_contrast": "Platforms opened (compared with last month)",
    "market_income_all_income_contrast": "Platform revenue (compared with last month)",
    "market_cash_title": "Cash Withdrawal Market ",
    "market_cash_apply_money": "Applied Amount (including tax)",
    "market_cash_unfinished_money": "Amount Not Withdrawn (including tax)",
    "market_cash_apply_user": "Application Withdrawal User",
    "income_monetary_unit_label": "unit",
    "income_monetary_unit_cny": "yuan",
    "income_monetary_unit_krw": "won",
    "income_monetary_unit_won": "won",
    "income_monetary_unit_usd": "dollar",
    "details_header_payee": "Payee",
    "details_table_apply_date": "Application Date",
    "details_table_settlement_period": "Settlement Cycle",
    "details_table_apply_amount": "Application Amount (including tax)",
    "details_table_tax_deduction": "Tax",
    "details_table_service_charge": "Service Charge",
    "details_table_transfer_status": "Payment Status",
    "details_table_action": "Operation",
    "details_table_transfer_status_ongoing": "Ongoing",
    "details_table_transfer_status_success": "Success",
    "details_table_transfer_status_failure": "Failure",
    "details_table_action_to_withdrawal": "Execution",
    "details_table_action_view_withdrawal": "View",
    "details_bank_info": "Bank Info",
    "details_bank_name": "bank name",
    "details_bank_account_number": "bank account",
    "details_bank_account_name": "account name",
    "details_bank_real": "real name",
    "details_bank_identity_number": "ID number",
    "details_bank_currency": "currency",
    "details_ip_ad_income": "IP advertising revenue (compared with last month)",
    "details_total_amount": "Total amount",
    "details_withdrawable_amount": "Withdrawable amount",
    "details_cumulative_withdrawal amount": "cumulative withdrawal amount",
    "withdrawal_modal_title_details": "Withdrawal Infomation",
    "withdrawal_modal_withdrawal_operation": "Withdrawal Operation",
    "withdrawal_modal_apply_date": "Application date",
    "withdrawal_modal_settlement_period": "Settlement cycle",
    "withdrawal_modal_withdrawal_detail": "Withdrawal detail",
    "withdrawal_modal_platform_income": "Platform income",
    "withdrawal_modal_ad_income": "Ad income",
    "withdrawal_modal_tax1": "Tax1",
    "withdrawal_modal_tax2": "Tax2",
    "withdrawal_modal_tax3": "Tax3",
    "withdrawal_modal_service_charge": "Service charge",
    "withdrawal_modal_after_tax_amount": "After-tax amount",
    "withdrawal_modal_payment_details": "Payment details",
    "withdrawal_modal_expected_date": "Expected date",
    "withdrawal_modal_exchange_rate": "Exchange rate",
    "withdrawal_modal_exchange_amount": "Exchange amount",
    "withdrawal_modal_personal_tax": "Personal income tax 3.3%",
    "withdrawal_modal_personal_paypal": "Paypal order number",
    "withdrawal_modal_payment_amount": "Payment amount",
    "withdrawal_modal_payment_status": "Payment status",
    "withdrawal_modal_cause_of_failure": "Cause of failure",
    "withdrawal_modal_currency": "Currency",
    "withdrawal_modal_expected_date_holder": "Select a date",
    "withdrawal_modal_exchange_rate_holder": "Input exchange rate",
    "withdrawal_modal_exchange_amount_holder": "Input exchange rate amount",
    "withdrawal_modal_personal_tax_holder": "Input personal income tax",
    "withdrawal_modal_payment_amount_holder": "Input payment amount",
    "withdrawal_modal_cause_of_failure_holder": "Input cause of failure",
    "withdrawal_modal_cause_failure_holder": "Choose cause of failure",
    "withdrawal_modal_failure_option_error": "Payment failed. PayPal account not found. Please enter the correct account information",
    "withdrawal_modal_failure_option_other": "Payment failed. To resolve the issue quickly, please inquire through Customer Service.",
    "withdrawal_modal_currency_holder": "Select a currency",
    "withdrawal_modal_personal_paypal_holder": "Input  payment order number"
  },
  "experimental": {
    "download_software": "Download Software",
    "software_terminal": "Terminal",
    "software_interface": "Interface",
    "more_exciting": "More exciting, stay tuned"
  },
  "notice": {
    "notice_send_notice": "Send internal notification",
    "notice_record": "Internal notification record",
    "notice_search_placeholder": "Search UID",
    "notice_send_target": "Recipient",
    "notice_send_content_title": "Original text content",
    "notice_send_title": "Title",
    "notice_send_content": "Content",
    "notice_send_link": "Link",
    "notice_send_title_placeholder": "Please enter a title",
    "notice_send_content_placeholder": "Please enter content",
    "notice_send_link_placeholder": "Please enter the link",
    "notice_ai_translation_btn": "AI translation",
    "notice_ai_translation_lang": "AI translation language",
    "notice_send_notification": "Send notification",
    "notice_send_user": "Develop users",
    "notice_record_page_title": "Internal notification sending record",
    "notice_record_search": "Search",
    "notice_record_show": "Show",
    "notice_record_tip": "pieces",
    "notice_record_all": "Total",
    "notice_record_refresh": "Refresh",
    "notice_record_sender": "Sender",
    "notice_record_title": "Title",
    "notice_record_send_time": "Sent time",
    "notice_record_details": "Details",
    "notice_record_view_details": "View details",
    "notice_details_modal_title": "Notification details",
    "notice_receiver_id": "Recipient ID",
    "notice_create_time": "Creation time",
    "notice_details_content": "Notification content",
    "notice_details_en": "English version",
    "notice_details_zh": "Chinese version",
    "notice_details_ko": "Korean version",
    "notice_select_all": "All",
    "notice_content_too_long": "The number of characters in the {0} translated notification content cannot exceed 1000.",
    "notice_translate_success": "AI translation successful",
    "notice_translate_error": "AI translation failed",
    "notice_send_success": "Notification sent successfully",
    "notice_send_error": "Notification sending failed",
    "notice_select_user": "Please select the recipient.",
    "notice_search_time": "Search send time:",
    "notice_start_time": "Start time",
    "notice_end_time": "End time",
    "notice_search": "Search {0}",
    "notice_reset": "Reset",
    "notice_clear_all": "Delete all",
    "notice_word_limit": "The number of characters cannot exceed 1,000.",
    "notice_send_confirm": "Would you like to send a notification to the users below?",
    "notice_selected_user": "Selected users:",
    "notice_all_user": "All users",
    "notice_send_ok": "Confirm",
    "notice_send_cancel": "Cancel"
  },
  "translation": {
    "srt_translation": "AI Subtitle",
    "srt_translation_success": "AI Subtitle was successful and is now downloading. Please check your download folder.",
    "srt_translation_error": "Failed to create. Please re-upload the file.",
    "srt_select_target_lang": "Please select the target language",
    "srt_select_translation_model": "Please select a translation model",
    "srt_select_translation_engine": "Please select a translation engine",
    "srt_select_chatgpt_model": "Please select a ChatGpt model",
    "srt_upload_file": "Please upload SRT/MP3 files",
    "srt_upload_btn": "File upload",
    "srt_translate_btn": "Generation",
    "srt_target_lang_description": "The uploaded file is expected to be translated into {0}.",
    "srt_subtitle": "The AI Subtitle uses Whisper AI, and it analyzes and translates MP3 and SRT files to produce an SRT file.",
    "srt_model_description_tiny": "Very fast, but the accuracy is acceptable",
    "srt_model_description_base": "Fast speed, high accuracy",
    "srt_model_description_small": "Normal speed, high accuracy",
    "srt_model_description_medium": "Slow speed but high accuracy",
    "srt_model_description_large": "Very slow, but very accurate",
    "srt_translate_working": "The AI is working hard to translate. Don't close the current browser window, the task will be interrupted.",
    "srt_file_type_error": "The file type is not supported",
    "srt_prompt": "Prompt",
    "srt_prompt_description": "A prompt is an instruction used to initiate a task, and it can be a question, directive, or sentence. The accuracy of the task depends on how clearly the prompt is written."
  }
};export default en