const kr = {
  "global": {
    "language": "한국어",
    "not_found_title": "웹페이지를 표시할 수 없습니다",
    "not_found_tips": "일부로 찾아오신게 아니라면 주소를 다시 한번 확인해주세요",
    "login_greeting_morning": "좋은 아침입니다",
    "login_greeting_afternoon": "즐거운 오후에요",
    "login_greeting_evening": "기분 좋은 밤입니다",
    "login_password_title": "이메일",
    "login_password_holder": "비밀번호를 입력해주세요",
    "login_password_error": "아차! 비밀 번호는 4-20자리만 입력할 수 있어요",
    "login_email_holder": "가입한 이메일을 입력해주세요",
    "login_email_error": "이메일 형식이 맞는지 확인 부탁드려요",
    "login_code_title": "핸드폰",
    "login_qrcode_title": "QR코드",
    "login_qrcode_tips": "{0}으로 스캔하여 로그인해주세요",
    "login_qrcode_feishu": "페이슈 앱",
    "login_qrcode_new_tips": "페이슈를 통해 큐알 코드 스캔 후 로그인 해주세요",
    "login_button": "로그인",
    "login_forgot": "비밀번호가 기억이 안나세요?",
    "login_logout": "로그아웃",
    "modal_cancel": "취소",
    "modal_delete": "삭제",
    "modal_create": "신규 추가",
    "modal_save": "저장",
    "modal_confirm": "확실한가요?",
    "btn_add": "추가",
    "btn_change": "수정",
    "btn_delete": "삭제",
    "btn_save": "저장",
    "btn_confirm": "확인",
    "message_success_create": "신규 추가 성공!",
    "message_error_create": "신규 추가 실패!",
    "message_success_update": "수정 성공!",
    "message_error_update": "수정 실패!",
    "message_success_delete": "삭제 성공!",
    "message_error_delete": "삭제 실패!",
    "message_success_upload": "업로드 성공!",
    "message_error_upload": "업로드 실패!",
    "message_no_token": "토큰 연결이 끊어졌습니다, 다시 로그인해주세요",
    "message_error_login_code": "로그인에 실패했어요, 카카오채널adoba에 오류상황을 남겨주세요",
    "message_no_permission": "권한이 없습니다",
    "upload_file_format": "지원 가능한 형식:",
    "upload_file_size": "최대 파일 크기: ",
    "form_holder_require": "여기는 꼭 입력해주셔야 해요!",
    "unselected_creator_tips": "데이터가 없습니다, 왼쪽에서 크리에이터를 선택해주세요",
    "platform_hao_kan": "하오칸 비디오",
    "platform_tencent": "텐센트 비디오",
    "platform_iqiyi": "아이치이",
    "platform_bilibili": "비리비리",
    "platform_xi_gua": "시과 비디오",
    "platform_wei_bo": "웨이보",
    "platform_youtube": "유투브",
    "platform_dou_yin": "또우인",
    "platform_red_book": "샤오홍슈",
    "platform_kuai_shou": "콰이쇼우",
    "platform_cloud_music": "왕이윈음악",
    "platform_wechat_video": "위챗 비디오채널",
    "platform_mei_pai": "메이파이",
    "platform_da_yu": "따위하오",
    "platform_dian_tao": "디엔타오",
    "platform_qq_world": "QQ미니월드",
    "platform_tiktok": "틱톡",
    "platform_instagram": "인스타그램",
    "platform_twitter": "트위터",
    "language_en": "영어",
    "language_zh": "중국어",
    "language_ko": "한국어"
  },
  "member": {
    "create_time": "생성 시간",
    "name": "계정명",
    "member_type": "플랜 레벨",
    "present_months": "개월 수",
    "validity_period": "유효기간",
    "remark": "설명",
    "new_gift": "신규 부여",
    "reset": "리셋",
    "search": "조회",
    "member_benefits": "플랜 혜택",
    "present_uid": "UID입력",
    "close": "닫기",
    "not_free": "유저 플랜 레벨이  FREE가 아닙니다.",
    "please_enter": "입력해 주세요.",
    "please_choose": "선택해 주세요.",
    "add_new_present": "새 혜택 추가",
    "split": "쉼표나 공백을 통해 일괄 입력이 가능합니다.",
    "month": "{0}개월",
    "create_success": "성공했습니다.",
    "create_fail": "실패했습니다.",
    "get_list_fail": "목록을 가져오는데 실패했습니다. ",
    "reviewer": "운영자"
  },
  "audit": {
    "platform_review": "플랫폼 심사",
    "content_review": "컨텐츠 심사",
    "operation": "작업",
    "op_success": "작업 성공 ",
    "op_fail": "작업 실페",
    "confirm_delete": "해당 실페 이유를 삭제하실까요?",
    "add_child": "새 하위 항목",
    "add_root": "새로운 항목 추가",
    "template_table": "오류 템플릿 양식",
    "save_error": "작성하지 않은 내용이 있어 정보 저장을 완료할 수 없습니다.",
    "audit_edit": "감사 구성",
    "qrcode_review": "인증번호 확인"
  },
  "enterprise": {
    "enterprise_name": "회사명",
    "business_license": "사업자등록번호",
    "member_number": "멤버 인원",
    "register_status": "가입상태",
    "operation_status": "운영상태",
    "create_time": "생성 일시",
    "register_time": "가입 일시",
    "creator": "운영자",
    "new_enterprise": "신규 기업",
    "create_new_account": "신규 기업 계정 등록",
    "enterprise_management": "기업 관리",
    "operation_status_inactivity": "비활성화",
    "operation_status_activity": "활성화",
    "create_success": "생성 완료",
    "create_fail": "생성 실패",
    "invite_code": "초대코드",
    "operation": "작업",
    "check": "확인하기",
    "detail_page": "더 알아보기",
    "get_basic_info_fail": "기업정보 불러오기 실패",
    "get_renew_info_fail": "기업 이용연장 정보 불러오기 실패",
    "email": "이메일",
    "paypal": "페이팔 계정",
    "paypal_order_number": "페이팔 주문서 정보",
    "basic_information": "기본정보",
    "renew_management": "사용 연장관리",
    "renew_handle": "연장 처리",
    "paid_time": "실결제일",
    "handle": "처리",
    "renew_status": "결제상태",
    "money": "결제 예정금액",
    "people": "D-7일 멤버 인원수",
    "end_time": "결제 예정일",
    "countdown_time": "D-7일",
    "unpaid": "미결제",
    "paid": "결제 완료",
    "handle_success": "처리 성공",
    "handle_fail": "처리 실패",
    "approve": "승인",
    "approve_time": "승인일시",
    "confirm_approve": "승인하시겠어요?",
    "confirm_approve_success": "승인 완료",
    "confirm_approve_fail": "승인 실패"
  },
  "dashboard": {
    "work_bench": "대시보드",
    "account_manage": "채널 관리",
    "road_manage": "유저 관리",
    "data_analysis": "데이터 분석",
    "income_manage": "수익 관리",
    "order_manage": "비즈니스 관리",
    "personal_center": "마이페이지",
    "feedback": "피드백",
    "experimental_center": "실험실",
    "close_sidebar": "메뉴 접기",
    "translation_manage": "AI 자막",
    "notice_manage": "알림 관리"
  },
  "account": {
    "search_placeholder": "adoba id/채널명/핸드폰 번호로 검색 가능",
    "export_operation_info": "운영 정보 다운로드",
    "add_new_account": "신규 크리에이터 추가",
    "creator_list_all": "모든 CR",
    "creator_list_fav": "관심있는 CR",
    "creator_list_manage": "나의 CR",
    "creator_list_sort_fans": "팔로워수",
    "creator_list_sort_views": "조회수",
    "creator_list_sort_uprate": "성장률",
    "header_no": "아도바 아이디",
    "header_country": "국가",
    "header_contract_end": "계약 종료",
    "header_cate": "카테고리",
    "header_type": "계약 형식",
    "header_expired": "만기됨",
    "header_days": "일",
    "header_operate_status": "운영 현황",
    "header_personal_Info": "개인 정보",
    "card_operate_platform": "운영 플랫폼",
    "card_operate_log": "운영 일지",
    "card_operate_info": "운영 정보",
    "card_business_info": "비즈니스 정보",
    "card_base_info": "크리에이터 기본 정보",
    "card_foreign_info": "중국외 플랫폼 정보",
    "card_passport_info": "여권 정보 (L1)",
    "card_contract_info": "계약 정보 (L2)",
    "card_bank_info": "은행 정보 (L3)",
    "platform_is_bind": "연동 완료",
    "platform_un_bind": "미연동",
    "platform_fans": "팔로워수",
    "platform_add": "신규 플랫폼 추가",
    "platform_name": "플랫폼 명",
    "platform_account_name": "채널 명",
    "platform_url": "채널 주소",
    "platform_uid": "플랫폼 UID",
    "platform_start_date": "첫 배포일",
    "platform_bind_phone": "가입 번호",
    "platform_bind_email": "가입 이메일",
    "platform_mcn_bind": "MCN 연동",
    "platform_withdrawal": "정산 연동",
    "platform_un_authorize": "수권 미완료",
    "platform_is_authorize": "수권 완료",
    "platform_main_page": "채널 메인페이지",
    "platform_password": "비밀번호",
    "platform_pid": "PID",
    "platform_tao_bao": "타오바오리엔멍",
    "platform_JD": "JD 리엔멍",
    "platform_pin_duo_duo": "핀뚜어뚜어",
    "platform_mo_gu": "모구지에",
    "platform_not_begun": "준비중",
    "platform_not_started": "첫 배포 준비",
    "platform_change_title": "플랫폼 정보 수정",
    "platform_holder_name": "플랫폼을 선택해주세요",
    "platform_holder_account_name": "채널 명을 입력해주세요",
    "platform_holder_url": "채널 메인 페이지 주소를 입력해주세요",
    "platform_holder_uid": "플랫폼 UID 를 입력해주세요",
    "platform_holder_start_date": "첫 영상 배포일을 선택해주세요",
    "platform_holder_bind_phone": "가입 번호를 입력해주세요",
    "platform_holder_bind_email": "가입 이메일을 입력해주세요",
    "platform_holder_mcn_bind": "MCN 연동 현황을 선택해주세요",
    "platform_holder_withdrawal": "정산 연동 현황을 선택해주세요",
    "platform_holder_join_page": "새창으로 메인 페이지 열기",
    "platform_holder_password": "비밀번호를 입력해주세요",
    "platform_holder_pid": "PID 를 입력해주세요",
    "platform_modal_confirm": "운영 플랫폼을 삭제하시겠습니까?",
    "platform_check_code": "국가 번호를 선택해주세요",
    "platform_check_email": "이메일 형식을 확인해주세요",
    "platform_check_phone": "전화번호 형식을 확인해주세요",
    "platform_check_password": "비밀 번호는 6-16자리만 입력할 수 있어요",
    "operate_status_operating": "운영중",
    "operate_status_cancelled": "해지",
    "operate_status_unsigned": "미계약",
    "operate_status_unprocessed": "미운영",
    "contract_type_personal": "개인",
    "contract_type_enterprise": "기업",
    "contract_type_mcn": "MCN",
    "operate_head": "운영 담당자",
    "operate_docking": "매니저",
    "operate_start_date": "운영 시작",
    "operate_start_day": "일",
    "operate_not_begun": "준비중",
    "operate_frequency": "업로드 주기",
    "operate_frequency_week": "주",
    "operate_frequency_month": "월",
    "operate_frequency_times": "번",
    "operate_note": "업로드 비고",
    "operate_delay": "업로드 딜레이",
    "operate_delay_can": "가능",
    "operate_delay_not": "불가",
    "operate_subtitles": "자막 이슈",
    "operate_order": "광고",
    "operate_order_can": "가능",
    "operate_order_not": "불가능",
    "operate_cooperate": "협력도",
    "operate_cover": "썸네일",
    "operate_cover_creator": "크리에이터 제공",
    "operate_cover_self": "자체 제작",
    "operate_source": "영상",
    "operate_source_creator": "크리에이터 제공",
    "operate_source_self": "자체 다운로드",
    "operate_major": "운영 주의 사항",
    "operate_change_title": "운영 정보 수정",
    "operate_add_title": "운영 정보 추가",
    "operate_creator_self": "CR 운영",
    "operate_repeat_error": "운영 담당자와 매니저는 동일하게 지정이 되지 않습니다",
    "operate_holder_head": "채널운영 담당자를 검색하세요",
    "operate_holder_docking": "소통을 담당하는 매니저를 검색하세요",
    "operate_holder_start_date": "최초 운영 시작일을 선택해주세요",
    "operate_holder_frequency": "업로드 주기를 입력해주세요",
    "operate_holder_number": "숫자를 입력해주세요",
    "operate_holder_note": "업로드 관련 사항을 입력해주세요",
    "operate_holder_delay": "업로드 연장 여부를 선택해주세요",
    "operate_holder_subtitles": "자막 이슈를 입력해주세요 ",
    "operate_holder_order": "비즈니스 가능 여부를 선택해주세요",
    "operate_holder_cover": "썸네일 제작 현황을 선택해주세요",
    "operate_holder_source": "영상 다운로드 방법을 선택해주세요",
    "operate_holder_major": "운영시 주의 사항을 필요한 만큼 적어주세요",
    "operate_check_frequency": "주기 형식을 선택해주세요",
    "person_base_check_frequency": "0 이상의 숫자만 입력 가능합니다",
    "person_base_check_times": "0 이상의 숫자만 입력 가능합니다",
    "business_price": "참고 가격",
    "business_add": "추가",
    "business_add_title": "비즈니스 정보 추가",
    "business_change_title": "비즈니스 정보 수정",
    "business_name": "플랫폼 명",
    "business_implant": "PPL 영상",
    "business_custom": "브랜디드 영상",
    "business_dynamic": "오리지널 포스팅",
    "business_forward": "공유 포스팅",
    "business_holder_name": "플랫폼을 선택해주세요",
    "business_holder_implant": "PPL 영상 가격을 설정해주세요",
    "business_holder_custom": "브랜디드 영상 가격을 설정해주세요",
    "business_holder_dynamic": "오리지널 포스팅 가격을 설정해주세요",
    "business_holder_forward": "공유 포스팅 가격을 설정해주세요",
    "log_update_content": "크리에이터 {0}의 {1} {2} 중 {3} 이(가) {4}에서 {5}(으)로 수정되었습니다",
    "log_update_pid_up": "크리에이터 {0}의 {1} {2} 중 {3} 의 {4}이(가) {5}에서 {6}(으)로 수정되었습니다",
    "log_update_pid_add_del": "크리에이터의 {0}의 {1} {2}, {4} 의 {5}이(가) {3} 되었습니다",
    "log_create_delete_content": "크리에이터 {0}의 {1} 내 {2}",
    "log_create_content": "크리에이터 {0} 의 {1}",
    "person_base_info": "크리에이터 기본 정보",
    "person_base_avatar": "프로필",
    "person_base_nickname": "중국 채널명",
    "person_base_adoba_id": "아도바 아이디",
    "person_base_nation": "국가",
    "person_base_phone": "핸드폰번호",
    "person_base_email": "이메일",
    "person_base_cate": "카테고리",
    "person_base_intro": "간단소개",
    "person_base_add": "신규 크리에이터 추가",
    "person_base_add_tip": "*관리 권한이 있어야만 추가/수정이 가능합니다",
    "person_message_success": "추가 성공",
    "person_message_error": "추가 실패",
    "person_message_error_details": "같은 채널명 혹은 adoba ID가 존재합니다",
    "person_base_modal_title": "크리에이터 기본 정보 수정",
    "person_base_holder_avatar": "사진 업로드",
    "person_base_holder_nickname": "채널명은 중복 등록이 안됩니다",
    "person_base_holder_id": "adoba ID는 중복 등록이 안됩니다",
    "person_base_holder_nation": "크리에이터 국가를 선택해주세요",
    "person_base_holder_phone": "핸드폰 번호는 중복 등록이 안됩니다",
    "person_base_holder_email": "연락이 가능한 이메일을 입력해주세요",
    "person_base_holder_cate": "채널 분류를 선택해주세요",
    "person_base_holder_intro": "크리에이터의 간단 소개를 입력해주세요",
    "person_base_check_code": "국가번호를 입력해주세요",
    "person_base_check_id": "아도바 아이디 형식이 틀립니다",
    "person_base_check_phone": "전화번호 형식이 틀립니다",
    "person_base_check_email": "이메일 형식이 틀립니다",
    "person_base_exist_nickname": "채널명이 이미 존재합니다",
    "person_base_exist_id": "아도바 아이디가 이미 존재합니다",
    "person_base_exist_phone": "전화번호가 이미 존재합니다",
    "person_overseas_platform": "플랫폼 명",
    "person_overseas_name": "채널 명",
    "person_overseas_url": "채널 주소",
    "person_overseas_uid": "UID",
    "person_overseas_modal_title": "중국외 플랫폼 정보 수정",
    "person_overseas_holder_platform": "플랫폼을 선택해주세요",
    "person_overseas_holder_name": "중국외 플랫폼 채널명을 입력해주세요",
    "person_overseas_holder_url": "중국외 플랫폼 채널 주소를 입력해주세요",
    "person_overseas_holder_uid": "UID를 입력해주세요",
    "person_overseas_check_uid": "숫자를 입력해주세요",
    "person_privacy_info": "크리에이터 개인 정보",
    "person_passport_no": "여권 번호",
    "person_passport_name": "여권 영문명",
    "person_passport_expire": "여권 유효기간",
    "person_passport_birth": "생년월일",
    "person_passport_address": "배송 주소",
    "person_passport_modal_title": "여권 정보 수정",
    "person_passport_holder_no": "여권 번호를 입력해주세요",
    "person_passport_holder_name": "여권 영문명을 입력해주세요",
    "person_passport_holder_expire": "여권 유효기간을 선택해주세요",
    "person_passport_holder_birth": "크리에이터의 생년월일을 선택해주세요",
    "person_passport_holder_address": "배송을 받을 수 있는 주소를 입력해주세요",
    "person_contract_no": "계약 번호",
    "person_contract_cate": "채널 분류",
    "person_contract_date": "계약 시작일",
    "person_contract_start_date": "계약 시작일",
    "person_contract_expire_date": "계약 종료일",
    "person_contract_operate": "운영 독점 여부",
    "person_contract_operate_true": "독점",
    "person_contract_operate_false": "비독점",
    "person_contract_operate_ratio": "채널 운영 수익 배분율",
    "person_contract_ad": "비즈니스 독점 여부",
    "person_contract_ad_ratio": "비즈니스 수익 배분율",
    "person_contract_remark": "계약 관련 비고",
    "person_contract_modal_title": "계약 정보 수정",
    "person_contract_remove": "해지 여부",
    "person_contract_remove_date_true": "해지",
    "person_contract_not_operating": "미운영 여부",
    "person_contract_not_operating_true": "미운영",
    "person_contract_remove_date": "계약해지일",
    "person_contract_not_operating_date": "미운영 전환일",
    "person_contract_true": "해지",
    "person_contract_false": "비독점",
    "person_contract_holder_no": "계약번호를 입력해주세요",
    "person_contract_holder_cate": "채널 분류를 선택해주세요",
    "person_contract_holder_operate": "운영 독점 여부를 선택해주세요",
    "person_contract_holder_operate_ratio": "크리에이터 배분율은 0~100 사이로 입력해주세요",
    "person_contract_holder_ad": "비즈니스 독점 여부를 선택해주세요",
    "person_contract_holder_ad_ratio": "크리에이터 배분율은 0~100 사이로 입력해주세요",
    "person_contract_holder_remove_date": "계약 해지 일자를 선택해주세요",
    "person_contract_holder_not_operating_date": "미운영 전환일자를 선택해주세요",
    "person_contract_holder_remark": "계약 관련 비고사항을 입력해주세요",
    "person_contract_check_operate": "크리에이터 배분율 형식이 틀립니다",
    "person_contract_check_ad_ratio": "크리에이터 배분율 형식이 틀립니다",
    "person_bank_nation": "은행 국가",
    "person_bank_name": "은행 명",
    "person_bank_account": "은행 계좌",
    "person_bank_address": "은행 영문 주소",
    "person_bank_swift_code": "은행 Swift Code",
    "person_bank_user_name": "이름",
    "person_bank_id_card": "신분증 번호",
    "person_bank_modal_title": "은행 정보 수정",
    "person_bank_holder_nation": "은행이 있는 국가를 선택해주세요",
    "person_bank_holder_name": "은행 명을 입력해주세요",
    "person_bank_holder_account": "은행 계좌를 입력해주세요",
    "person_bank_holder_address": "은행 영문 주소를 입력해주세요",
    "person_bank_holder_swift_code": "은행 Swift Code를 입력해주세요",
    "person_bank_holder_user_name": "은행에 개설된 이름을 입력해주세요",
    "person_bank_holder_id_card": "주민등록번호를 입력해주세요",
    "passport_dashboard_title": "서명된 여권 스캔본",
    "passport_handhold_title": "서명된 여권과 본인 셀카",
    "person_level": "멤버 등급",
    "account_type": "계정 분류",
    "bind_mcn": "소속 MCN"
  },
  "feedback": {
    "feedback_type": "피드백 종류",
    "type_all": "전체",
    "type_login": "로그인 문의",
    "type_app": "앱 이슈 문의",
    "type_suggest": "건의사항",
    "type_other": "기타 문의"
  },
  "road": {
    "search_placeholder": "uid/채널명/email 번호로 검색 가능",
    "creator_list_applied": "개통 신청",
    "creator_list_open_manage": "채널 개통 관리",
    "creator_list_all": "전체",
    "creator_status_cancelled": "탈퇴",
    "check_identity": "제출 서류 보기",
    "header_notice": "마케팅 수신",
    "header_notice_open": "동의",
    "header_notice_close": "미동의",
    "identity_info": "서류 정보",
    "passport_info": "신분인증 정보",
    "overseas_info": "운영채널 정보",
    "account_info": "채널 가입 정보",
    "info_confirm": "확인",
    "account_avatar": "프로필 사진",
    "account_name": "채널 명",
    "account_name_holder": "채널 명을 입력해주세요",
    "account_introduction": "채널 소개",
    "account_introduction_holder": "채널 소개를 입력해주세요",
    "account_type": "카테고리",
    "account_type_holder": "카테고리를 선택해주세요",
    "account_phone": "채널 개설할 핸드폰 번호",
    "account_phone_holder": "핸드폰 번호를 입력해주세요",
    "account_email": "채널 개설할 이메일",
    "account_email_holder": "이메일을 입력해주세요",
    "platform_case": "플랫폼 선택",
    "overseas_platform": "운영중인 플랫폼",
    "overseas_platform_holder": "현재 운영중인 플랫폼을 선택해주세요",
    "overseas_link": "현재 운영 채널 링크",
    "overseas_link_holder": "현재 운영 채널 링크를 입력해주세요",
    "overseas_link_error": "운영중 플랫폼 링크가 부정확합니다, http 혹은 https 로 시작되는 확인해주세요",
    "overseas_link_method": "왜 현재 운영 채널 링크가 필요한가요?",
    "overseas_link_method_tips": "중국 플랫폼과의 적합성을 심사하기 위해 필요합니다",
    "overseas_method": "제출 서류 예시",
    "overseas_nickname": "운영중인 플랫폼 채널명",
    "overseas_nickname_holder": "현재 운영중인 플랫폼 채널명을 입력해주세요",
    "overseas_upload_proof": "파일 업로드",
    "overseas_main_page": "스튜디오 대쉬보드 스크린샷",
    "overseas_upload_main_page": "모바일 혹은 데스크탑 버젼의 스튜디오 대쉬보드 스크린샷을 업로드해주세요",
    "overseas_main_page_tips": "채널 개인 인증을 위해 필요합니다",
    "overseas_backstage": "대쉬보드와 본인 인증 셀카",
    "overseas_upload_backstage": "대쉬보드와 본인 인증 셀카를 업로드해주세요",
    "overseas_backstage_tips": "대쉬보드가 본인임을 인증하기 위해 필요한 서류입니다",
    "personal_passport_name": "여권 영문명",
    "personal_passport_name_holder": "여권의 영문명을 입력해주세요",
    "personal_passport_number": "여권번호",
    "personal_passport_number_holder": "여권번호를 입력해주세요",
    "personal_passport_date": "여권 유효기간",
    "personal_passport_date_holder": "여권 유효기간을 입력해주세요",
    "personal_passport_date_error": "여권 유효기간이 6개월 미만입니다",
    "personal_upload_proof": "파일 업로드",
    "personal_upload_passport": "여권 스캔본",
    "personal_upload_passport_holder": "여권 스캔본을 업로드해주세요",
    "personal_upload_passport_tips": "여권 스캔본은 본인 인증을 위해 필요합니다",
    "personal_method": "제출 서류 예시",
    "personal_passport_hold": "여권과 본인의 셀카",
    "personal_passport_hold_holder": "본인이 여권을 들고 찍은 셀카를 업로드해주세요",
    "personal_passport_hold_tips": "본인 인증을 위해 필요합니다",
    "personal_passport_success": "여권 정보 수정을 성공하였습니다",
    "personal_passport_fail": "여권 정보 수정을 실패하였습니다",
    "personal_oversea_success": "운영 채널 정보 수정을 성공하였습니다",
    "personal_oversea_fail": "운영 채널 정보 수정을 실패하였습니다",
    "confirm_info": "확인",
    "submit_material_success": "채널개통 서류를 제출하였습니다",
    "submit_material_fail": "채널개통 서류 제출에 실패하였습니다",
    "header_title": "채널 개통 관리",
    "header_no": "UID",
    "header_cate": "카테고리",
    "table_platform": "플랫폼",
    "table_apply_date": "신청일자",
    "table_off_site_problem": "적합성",
    "table_data_problem": "서류",
    "table_confirm_problem": "인증",
    "table_finished": "개설",
    "table_finished_date": "완료일자",
    "table_account_name": "채널명",
    "table_phone": "개설 핸드폰",
    "table_email": "개설 메일",
    "table_password": "개설 비밀번호",
    "table_action": "액션",
    "progress_not_start": "준비중",
    "progress_ongoing": "진행중",
    "progress_completed": "완료",
    "progress_open_completed": "개통 완료",
    "progress_open_failed": "개통 실패",
    "progress_modal_title": "채널 개설 진행도",
    "table_apply_date_remaining": "D-{0}day",
    "table_apply_date_expire": "D+{0}day",
    "table_modal_steps_tips": "데이터 수집하기를 활성화해야 완료가 가능합니다",
    "table_modal_steps_to_open": "데이터 수집하기 활성화",
    "table_modal_password_holder": "개설 비밀번호를 입력해주세요",
    "table_modal_note": "메모",
    "table_modal_note_holder": "메모를 적어주세요",
    "modal_message_err_status": "프로세스 업데이트에 문제가 발생했습니다",
    "modal_message_err_password": "비밀번호 업데이트에 문제가 발생했습니다",
    "modal_message_err_tracking": "데이터 수집하기를 먼저 활성화해주세요!",
    "platform_account_name_error": "채널명은 중국어, 영어, 숫자만 입력 가능합니다",
    "platform_is_stopped": "데이터 수집하기",
    "platform_is_stopped_confirm": "중요! 필독을 꼭 읽어주시고, 활성화를 해주세요",
    "platform_is_stopped_must_read": "필독",
    "modal_footer_previous": "개설 진행",
    "modal_footer_previous_btn": "전단계로 되돌리기",
    "all_platform_modal_tips_attention": "주의사항",
    "all_platform_modal_tips_link": "채널 주소를 반드시 정확하게 입력해야 합니다",
    "all_platform_modal_tips_mcn": "MCN 연동이 필요합니다",
    "all_platform_modal_tips_nonsupport": "샤오홍슈는 잠시 활성화가 불가하니 조금만 기다려주세요",
    "all_platform_modal_tips_times": "다음 수정은 6시간 후에 가능합니다",
    "all_platform_modal_tips_ failure": "활성화 후 5-10분 정도 기다려야 성공 결과여부가 나옵니다, 활성화 실패시 개발부서에게 문의주세요",
    "data_tracking_confirm_open_title": "데이터 수집을 활성화하시겠습니까?",
    "data_tracking_confirm_close_title": "데이터 수집을 중지하시겠습니까?",
    "data_tracking_countdown_label": "다음 작업까지 대기 시간",
    "data_list_count": "총 {} 개의 데이터"
  },
  "income": {
    "header_view_market_btn": "데이터 대쉬보드",
    "header_export_settlement_btn": "정산 데이터 다운",
    "header_tax_manage_btn": "수수료 및 세금",
    "tax_manage_modal_title": "수수료 및 세금",
    "tax_manage_tax_item": "항목{0}",
    "tax_manage_name": "명칭",
    "tax_manage_rate": "수수료율/세율",
    "tax_manage_text": "설명",
    "tax_manage_name_holder": "중국 세금명을 적어주세요",
    "tax_manage_rate_holder": "중국 세금 비율을 적어주세요",
    "tax_manage_text_holder": "중국 세금 설명을 적어주세요",
    "export_settlement_file_name": "정산 정보",
    "export_settlement_title_tips": "전체 다운로드",
    "export_settlement_date_range": "시작일",
    "export_settlement_status": "다운로드 타입",
    "export_settlement_date_start_holder": "시작일을 선택해주세요",
    "export_settlement_date_end_holder": "종료일을 선택해주세요",
    "export_settlement_status_holder": "다운로드 타입을 선택해주세요",
    "creator_list_tabs_apply": "정산 신청",
    "creator_list_tabs_applied": "신청 완료",
    "creator_list_tabs_reviewed": "이체 대기",
    "creator_list_tabs_transfered": "이체 완료",
    "creator_list_tabs_rejected": "거절",
    "creator_list_tabs_failed": "이체 실패",
    "creator_list_tabs_all": "전체",
    "market_income_title": "수익 대쉬보드",
    "market_income_subtitle": "이틀 전 기준 데이터입니다",
    "market_income_all_income": "전체 플랫폼 누적 수익",
    "market_income_all_opens": "전체 플랫폼 개통 수",
    "market_income_all_opens_contrast": "플랫폼 개통 수 (전달대비)",
    "market_income_all_income_contrast": "전체 플랫폼 수익 (전달대비)",
    "market_cash_title": "정산 대쉬보드",
    "market_cash_apply_money": "정산된 금액 (세금 포함)",
    "market_cash_unfinished_money": "미정산된 금액 (세금 포함)",
    "market_cash_apply_user": "정산 신청한 유저",
    "income_monetary_unit_label": "단위",
    "income_monetary_unit_cny": "위안",
    "income_monetary_unit_krw": "원",
    "income_monetary_unit_won": "원",
    "income_monetary_unit_usd": "달러",
    "details_header_payee": "입금인",
    "details_table_apply_date": "신청일자",
    "details_table_settlement_period": "정산 기간",
    "details_table_apply_amount": "신청 금액 (세금 포함)",
    "details_table_tax_deduction": "중국 세금",
    "details_table_service_charge": "서비스 수수료",
    "details_table_transfer_status": "정산 현황",
    "details_table_action": "액션",
    "details_table_transfer_status_ongoing": "처리중",
    "details_table_transfer_status_success": "정산 완료",
    "details_table_transfer_status_failure": "정산 실패",
    "details_table_action_to_withdrawal": "정산 집행",
    "details_table_action_view_withdrawal": "정산 내역 보기",
    "details_bank_info": "은행 정보",
    "details_bank_name": "은행명",
    "details_bank_account_number": "계좌 번호",
    "details_bank_account_name": "예금주",
    "details_bank_real": "소득신고자",
    "details_bank_identity_number": "소득신고자 주민번호 ",
    "details_bank_currency": "정산 화폐",
    "details_ip_ad_income": "IP 광고 수익 (전달대비)",
    "details_total_amount": "총 수익",
    "details_withdrawable_amount": "정산 신청 가능 금액",
    "details_cumulative_withdrawal amount": "정산된 총 수익",
    "withdrawal_modal_title_details": "정산 현황",
    "withdrawal_modal_withdrawal_operation": "상세보기",
    "withdrawal_modal_apply_date": "신청 일자",
    "withdrawal_modal_settlement_period": "정산 기간 범위",
    "withdrawal_modal_withdrawal_detail": "정산 상세 내역",
    "withdrawal_modal_platform_income": "플랫폼 수익",
    "withdrawal_modal_ad_income": "IP 비즈니스 수익",
    "withdrawal_modal_tax1": "세금1",
    "withdrawal_modal_tax2": "세금2",
    "withdrawal_modal_tax3": "세금3",
    "withdrawal_modal_service_charge": "서비스 수수료",
    "withdrawal_modal_after_tax_amount": "수익 정산 금액",
    "withdrawal_modal_payment_details": "정산 명세서",
    "withdrawal_modal_expected_date": "예정일",
    "withdrawal_modal_exchange_rate": "적용 환율",
    "withdrawal_modal_exchange_amount": "한화 정산 금액",
    "withdrawal_modal_personal_tax": "개인소득세 3.3%",
    "withdrawal_modal_personal_paypal": "Paypal 주문 번호",
    "withdrawal_modal_payment_amount": "정산 금액",
    "withdrawal_modal_payment_status": "정산 현황",
    "withdrawal_modal_cause_of_failure": "실패 이유",
    "withdrawal_modal_currency": "정산 화폐",
    "withdrawal_modal_expected_date_holder": "일자를 선택해주세요",
    "withdrawal_modal_exchange_rate_holder": "환율을 입력해주세요",
    "withdrawal_modal_exchange_amount_holder": "환율 적용 금액을 적어주세요",
    "withdrawal_modal_personal_tax_holder": "개익소득세를 입력해주세요",
    "withdrawal_modal_payment_amount_holder": "최종 정산 금액을 적어주세요",
    "withdrawal_modal_cause_of_failure_holder": "실패 이유를 적어주세요",
    "withdrawal_modal_cause_failure_holder": "실패 이유를 선택하세요",
    "withdrawal_modal_failure_option_error": "결제에 실패했어요. 찾을 수 없는 페이팔 계정이에요. 정확한 계정정보를 입력해주세요. ",
    "withdrawal_modal_failure_option_other": "결제에 실패했어요. 빠른 문제 해결을 위해, 고객센터를 통해 문의를 남겨주세요.",
    "withdrawal_modal_currency_holder": "정산 화폐를 선택해주세요",
    "withdrawal_modal_personal_paypal_holder": "입금 주문 번호를 입력하십시오."
  },
  "experimental": {
    "download_software": "로그인 프로그램 다운로드",
    "software_terminal": "터미널 버젼",
    "software_interface": "인터페이스 버젼",
    "more_exciting": "더 많은 것들을 준비하고 있습니다"
  },
  "notice": {
    "notice_send_notice": "알림 발송",
    "notice_record": "알림 발송 리스트",
    "notice_search_placeholder": "UID 검색",
    "notice_send_target": "발송 대상",
    "notice_send_content_title": "원문 발송 내용",
    "notice_send_title": "제목",
    "notice_send_content": "알림 내용",
    "notice_send_link": "링크",
    "notice_send_title_placeholder": "제목을 작성해주세요",
    "notice_send_content_placeholder": "알림 내용을 작성해주세요",
    "notice_send_link_placeholder": "링크를 넣어주세요",
    "notice_ai_translation_btn": "AI 번역",
    "notice_ai_translation_lang": "AI 번역 언어",
    "notice_send_notification": "알림 발송",
    "notice_send_user": "수신 유저",
    "notice_record_page_title": "알림 발송 리스트",
    "notice_record_search": "검색",
    "notice_record_show": "표기",
    "notice_record_tip": "개",
    "notice_record_all": "총",
    "notice_record_refresh": "갱신",
    "notice_record_sender": "발송인",
    "notice_record_title": "제목",
    "notice_record_send_time": "발송시간",
    "notice_record_details": "상세",
    "notice_record_view_details": "상세보기",
    "notice_details_modal_title": "알림",
    "notice_receiver_id": "수신자 ID",
    "notice_create_time": "작성 시간",
    "notice_details_content": "알림 내용",
    "notice_details_en": "영어 버젼",
    "notice_details_zh": "중국어 버젼",
    "notice_details_ko": "한국어 버젼",
    "notice_select_all": "전부",
    "notice_content_too_long": "{0}로 번역 후 내용은 1,000자를 초과할 수 없습니다.",
    "notice_translate_success": "AI 번역 성공",
    "notice_translate_error": "AI 번역 실패",
    "notice_send_success": "알림 발송 성공",
    "notice_send_error": "알림 발송 실패",
    "notice_select_user": "알림 받을 특정유저를 선택해주세요.",
    "notice_search_time": "검색 발송 시간:",
    "notice_start_time": "시작 시간",
    "notice_end_time": "종료 시간",
    "notice_search": "검색 {0}",
    "notice_reset": "재설정",
    "notice_clear_all": "전부 삭제",
    "notice_word_limit": "글자 수는 1,000자를 넘을 수 없습니다.",
    "notice_send_confirm": "아래 유저에게 알림을 발송할까요?",
    "notice_selected_user": "선택된 유저:",
    "notice_all_user": "전체 유저",
    "notice_send_ok": "확인",
    "notice_send_cancel": "취소"
  },
  "translation": {
    "srt_translation": "AI 자막",
    "srt_translation_success": "성공적으로 생성이 완료되어 다운로드 중입니다. 다운로드 폴더를 확인해주세요.",
    "srt_translation_error": "생성에 실패하여, 다시 파일을 업로드해주셔야 합니다.",
    "srt_select_target_lang": "번역할 언어를 선택해주세요.",
    "srt_select_translation_model": "번역 처리속도 모델을 선택해주세요.",
    "srt_select_translation_engine": "번역 엔진을 선택하십시오.",
    "srt_select_chatgpt_model": "GPT모델을 선택하세요.",
    "srt_upload_file": "SRT/MP3 파일을 업로드해주세요.",
    "srt_upload_btn": "파일 업로드",
    "srt_translate_btn": "AI 자막 생성",
    "srt_target_lang_description": "업로드한 파일을 {0}로 번역 완료될 예정입니다",
    "srt_subtitle": "AI 자막은 Whisper AI를 활용하여, MP3과 SRT 파일을 분석/번역하여 SRT 파일로 산출됩니다.",
    "srt_model_description_tiny": "엄청 빠르지만, 정확도가 높진 않습니다.",
    "srt_model_description_base": "비교적 빠르고, 정확도가 높습니다.",
    "srt_model_description_small": "일반적이지만, 정확도가 더 높습니다.",
    "srt_model_description_medium": "느리지만, 정확도가 좀더 높습니다.",
    "srt_model_description_large": "매우 느리지만, 정확도는 가장 높습니다.",
    "srt_translate_working": "AI가 열심히 번역 중입니다, 현재 브라우저 창을 닫으시면 작업이 중지됩니다.",
    "srt_file_type_error": "파일 형식은 지원되지 않습니다",
    "srt_prompt": "프롬프트",
    "srt_prompt_description": "프롬프트란 작업을 시작하기 위해 사용되는 지시사항이며, 이는 질문, 지시사항 또는 문장 일 수 있습니다. 작업의 정확성은 프롬프트가 얼마나 명확하게 작성되는 지에 따라 달라집니다."
  }
};export default kr