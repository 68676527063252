const zh = {
  "global": {
    "language": "中文",
    "not_found_title": "没有您要找的页面",
    "not_found_tips": "可能是路径错误导致",
    "login_greeting_morning": "上午好!",
    "login_greeting_afternoon": "下午好!",
    "login_greeting_evening": "晚上好!",
    "login_password_title": "密码登录",
    "login_password_holder": "请输入密码",
    "login_password_error": "密码位数4-20位",
    "login_email_holder": "请输入邮箱",
    "login_email_error": "请确认邮箱是否正确",
    "login_code_title": "验证码登录",
    "login_qrcode_title": "扫码登录",
    "login_qrcode_tips": "请使用{0}扫码登录",
    "login_qrcode_feishu": "飞书App",
    "login_qrcode_new_tips": "使用飞书扫一扫登录 adoba studio",
    "login_button": "登录",
    "login_forgot": "忘记密码",
    "login_logout": "退出账户",
    "modal_cancel": "取消",
    "modal_delete": "删除",
    "modal_create": "创建",
    "modal_save": "保存",
    "modal_confirm": "是否确定?",
    "btn_add": "添加",
    "btn_change": "修改",
    "btn_delete": "删除",
    "btn_save": "保存",
    "btn_confirm": "确定",
    "message_success_create": "创建成功！",
    "message_error_create": "创建失败！",
    "message_success_update": "修改成功！",
    "message_error_update": "修改失败！",
    "message_success_delete": "删除成功！",
    "message_error_delete": "删除失败！",
    "message_success_upload": "上传成功！",
    "message_error_upload": "上传失败！",
    "message_no_token": "登录超时，请重新登录！",
    "message_error_login_code": "登录错误，请联系开发部门！",
    "message_no_permission": "没有权限！",
    "upload_file_format": "文件格式仅支持：",
    "upload_file_size": "文件必须小于",
    "form_holder_require": "请输入此项!",
    "unselected_creator_tips": "暂无数据，请先在左侧选择达人",
    "platform_hao_kan": "好看视频",
    "platform_tencent": "腾讯视频",
    "platform_iqiyi": "爱奇艺",
    "platform_bilibili": "哔哩哔哩",
    "platform_xi_gua": "西瓜视频",
    "platform_wei_bo": "微博",
    "platform_youtube": "Youtube",
    "platform_dou_yin": "抖音",
    "platform_red_book": "小红书",
    "platform_kuai_shou": "快手",
    "platform_cloud_music": "网易云音乐",
    "platform_wechat_video": "微信视频号",
    "platform_mei_pai": "美拍",
    "platform_da_yu": "大鱼号",
    "platform_dian_tao": "点淘",
    "platform_qq_world": "QQ小世界",
    "platform_tiktok": "TikTok",
    "platform_instagram": "Instagram",
    "platform_twitter": "Twitter",
    "language_en": "英文",
    "language_zh": "中文",
    "language_ko": "韩文"
  },
  "member": {
    "create_time": "创建时间",
    "name": "账号名",
    "member_type": "会员类型",
    "present_months": "赠送月数",
    "validity_period": "有效期",
    "remark": "备注",
    "new_gift": "新增赠送",
    "reset": "重置",
    "search": "查询",
    "member_benefits": "会员权益",
    "present_uid": "赠送UID",
    "close": "关闭",
    "not_free": "以下用户会员等级不是FREE，不支持权益赠送，请修改后重新输入",
    "please_enter": "请输入",
    "please_choose": "请选择",
    "add_new_present": "添加新的权益赠送",
    "split": "可通过”逗号“或\"空格\"分词批量输入",
    "month": "{0}个月",
    "create_success": "新增成功",
    "create_fail": "新增失败",
    "get_list_fail": "获取列表数据失败",
    "reviewer": "操作人"
  },
  "audit": {
    "platform_review": "平台审核",
    "content_review": "内容审核",
    "operation": "操作",
    "op_success": "操作成功",
    "op_fail": "操作失败",
    "confirm_delete": "确认删除此条失败原因吗?",
    "add_child": "新增子项",
    "add_root": "新增一项",
    "template_table": "错误模板表格",
    "save_error": "有内容未填写，无法完成信息保存",
    "audit_edit": "审核配置",
    "qrcode_review": "验证码审核"
  },
  "enterprise": {
    "enterprise_name": "企业名称",
    "business_license": "营业执照",
    "member_number": "成员数量",
    "register_status": "注册状态",
    "operation_status": "运营状态",
    "create_time": "创建时间",
    "register_time": "注册时间",
    "creator": "操作人",
    "new_enterprise": "新增企业",
    "create_new_account": "创建新的企业账户",
    "enterprise_management": "企业管理",
    "operation_status_inactivity": "未激活",
    "operation_status_activity": "已激活",
    "create_success": "创建成功",
    "create_fail": "创建失败",
    "invite_code": "邀请码",
    "operation": "操作",
    "check": "查看",
    "detail_page": "详情页",
    "get_basic_info_fail": "获取企业基本信息失败",
    "get_renew_info_fail": "获取企业续费信息失败",
    "email": "邮箱",
    "paypal": "paypal账户",
    "paypal_order_number": "paypal订单号",
    "basic_information": "基本信息",
    "renew_management": "续费管理",
    "renew_handle": "续费处理",
    "paid_time": "实际付款日期",
    "handle": "处理",
    "renew_status": "付款状态",
    "money": "应付款金额",
    "people": "D-7日成员人数",
    "end_time": "应付款日",
    "countdown_time": "D-7日",
    "unpaid": "未支付",
    "paid": "已支付",
    "handle_success": "处理成功",
    "handle_fail": "处理失败",
    "approve": "批准",
    "approve_time": "批准时间",
    "confirm_approve": "是否确认批准",
    "confirm_approve_success": "批准成功",
    "confirm_approve_fail": "批准失败"
  },
  "dashboard": {
    "work_bench": "工作台",
    "account_manage": "账号管理",
    "road_manage": "用户管理",
    "data_analysis": "数据分析",
    "income_manage": "收益管理",
    "order_manage": "商单管理",
    "personal_center": "个人中心",
    "feedback": "意见反馈",
    "experimental_center": "实验中心",
    "close_sidebar": "收起侧边栏",
    "translation_manage": "AI 字幕",
    "notice_manage": "通知管理"
  },
  "account": {
    "search_placeholder": "搜索adoba id/账号名/手机号",
    "export_operation_info": "导出运营信息",
    "add_new_account": "新增账号",
    "creator_list_all": "全部",
    "creator_list_fav": "收藏",
    "creator_list_manage": "我管理的",
    "creator_list_sort_fans": "粉丝数",
    "creator_list_sort_views": "播放量",
    "creator_list_sort_uprate": "成长率",
    "header_no": "编号",
    "header_country": "国家",
    "header_contract_end": "签约结束",
    "header_cate": "分类",
    "header_type": "类型",
    "header_expired": "已过期",
    "header_days": "天",
    "header_operate_status": "运营现状",
    "header_personal_Info": "个人信息",
    "card_operate_platform": "运营平台",
    "card_operate_log": "运营日志",
    "card_operate_info": "运营信息",
    "card_business_info": "商务信息",
    "card_base_info": "达人基本信息",
    "card_foreign_info": "海外平台信息",
    "card_passport_info": "护照信息(L1)",
    "card_contract_info": "合同信息(L2)",
    "card_bank_info": "银行信息(L3)",
    "platform_is_bind": "已绑定",
    "platform_un_bind": "未绑定",
    "platform_fans": "粉丝数",
    "platform_add": "添加运营平台",
    "platform_name": "平台名称",
    "platform_account_name": "账号名称",
    "platform_url": "主页地址",
    "platform_uid": "平台UID",
    "platform_start_date": "首发时间",
    "platform_bind_phone": "绑定手机号",
    "platform_bind_email": "绑定邮箱",
    "platform_mcn_bind": "MCN绑定",
    "platform_withdrawal": "提现情况",
    "platform_un_authorize": "未授权",
    "platform_is_authorize": "已授权",
    "platform_main_page": "账号主页",
    "platform_password": "密码",
    "platform_pid": "PID",
    "platform_tao_bao": "淘宝联盟",
    "platform_JD": "京东联盟",
    "platform_pin_duo_duo": "拼多多",
    "platform_mo_gu": "蘑菇街",
    "platform_not_begun": "未开始",
    "platform_not_started": "未首发",
    "platform_change_title": "修改平台信息",
    "platform_holder_name": "请选择平台名称",
    "platform_holder_account_name": "请输入账号名称",
    "platform_holder_url": "请输入主页地址",
    "platform_holder_uid": "请输入UID",
    "platform_holder_start_date": "请选择首发时间",
    "platform_holder_bind_phone": "请输入绑定手机号",
    "platform_holder_bind_email": "请输入绑定邮箱",
    "platform_holder_mcn_bind": "请选择MCN绑定情况",
    "platform_holder_withdrawal": "请选择提现情况",
    "platform_holder_join_page": "打开页面",
    "platform_holder_password": "请输入密码",
    "platform_holder_pid": "请输入PID",
    "platform_modal_confirm": "确定删除该运营平台吗？",
    "platform_check_code": "请选择国家代码！",
    "platform_check_email": "邮箱格式错误！",
    "platform_check_phone": "手机号格式错误！",
    "platform_check_password": "密码位数6-16位",
    "operate_status_operating": "运营中",
    "operate_status_cancelled": "解约",
    "operate_status_unsigned": "未签约",
    "operate_status_unprocessed": "未运营",
    "contract_type_personal": "个人",
    "contract_type_enterprise": "企业",
    "contract_type_mcn": "MCN",
    "operate_head": "运营负责人",
    "operate_docking": "对接人",
    "operate_start_date": "运营开始",
    "operate_start_day": "天",
    "operate_not_begun": "未开始",
    "operate_frequency": "更新频率",
    "operate_frequency_week": "周",
    "operate_frequency_month": "月",
    "operate_frequency_times": "次",
    "operate_note": "更新备注",
    "operate_delay": "可否延期",
    "operate_delay_can": "可以延期",
    "operate_delay_not": "不可延期",
    "operate_subtitles": "字幕问题",
    "operate_order": "可否接单",
    "operate_order_can": "可接单",
    "operate_order_not": "不可接单",
    "operate_cooperate": "配合度",
    "operate_cover": "封面情况",
    "operate_cover_creator": "达人提供",
    "operate_cover_self": "自制",
    "operate_source": "视频来源",
    "operate_source_creator": "达人提供",
    "operate_source_self": "自行下载",
    "operate_major": "注意事项",
    "operate_change_title": "修改运营信息",
    "operate_add_title": "添加运营信息",
    "operate_creator_self": "CR自营",
    "operate_repeat_error": "对接人和负责人不能相同！",
    "operate_holder_head": "请搜索运营负责人",
    "operate_holder_docking": "请搜索对接人",
    "operate_holder_start_date": "请选择运营开始时间",
    "operate_holder_frequency": "请输入更新次数",
    "operate_holder_number": "请输入数字",
    "operate_holder_note": "请输入更新备注",
    "operate_holder_delay": "请选择可否延期",
    "operate_holder_subtitles": "请输入字幕问题",
    "operate_holder_order": "请选择可否接单",
    "operate_holder_cover": "请选择封面情况",
    "operate_holder_source": "请选择视频来源",
    "operate_holder_major": "请输入注意事项",
    "operate_check_frequency": "请选择频率类型！",
    "person_base_check_frequency": "请输入频率，必须为大于或等于0的数字！",
    "person_base_check_times": "请输入次数，必须为大于或等于0的数字！",
    "business_price": "参考价格",
    "business_add": "添加",
    "business_add_title": "添加商务信息",
    "business_change_title": "修改商务信息",
    "business_name": "平台名称",
    "business_implant": "植入视频",
    "business_custom": "定制视频",
    "business_dynamic": "动态价格",
    "business_forward": "转发价格",
    "business_holder_name": "请输入平台名称",
    "business_holder_implant": "请输入植入视频价格",
    "business_holder_custom": "请输入定制视频价格",
    "business_holder_dynamic": "请输入动态价格",
    "business_holder_forward": "请输入转发价格",
    "log_update_content": "达人'{0}'的{1}{2}, '{3}'从'{4}'变更为'{5}'",
    "log_update_pid_up": "达人'{0}'的{1}{2}中的'{3}', '{4}'从'{5}'更新为'{6}'",
    "log_update_pid_add_del": "达人'{0}'的{1}'{2}',{3}'{4}'的{5}",
    "log_create_delete_content": "达人'{0}'在{1}中的{2}",
    "log_create_content": "达人{0}的{1}",
    "person_base_info": "基本信息",
    "person_base_avatar": "头像",
    "person_base_nickname": "昵称",
    "person_base_adoba_id": "adoba ID",
    "person_base_nation": "国籍",
    "person_base_phone": "手机号",
    "person_base_email": "邮箱",
    "person_base_cate": "分类",
    "person_base_intro": "简介",
    "person_base_add": "添加新的达人",
    "person_base_add_tip": "*只有具有管理员权限的用户才能添加/修改",
    "person_message_success": "创建成功！",
    "person_message_error": "创建失败！",
    "person_message_error_details": "昵称或者adobaID已存在！",
    "person_base_modal_title": "修改达人基本信息",
    "person_base_holder_avatar": "上传头像",
    "person_base_holder_nickname": "昵称不能重复",
    "person_base_holder_id": "adoba ID不能重复",
    "person_base_holder_nation": "请选择国籍",
    "person_base_holder_phone": "手机号不能重复",
    "person_base_holder_email": "请输入邮箱",
    "person_base_holder_cate": "请选择分类",
    "person_base_holder_intro": "请输入达人简介",
    "person_base_check_code": "请选择国家代码！",
    "person_base_check_id": "adoba ID格式错误！",
    "person_base_check_phone": "手机号格式错误！",
    "person_base_check_email": "邮箱格式错误！",
    "person_base_exist_nickname": "昵称已存在！",
    "person_base_exist_id": "adoba ID已存在！",
    "person_base_exist_phone": "手机号已存在！",
    "person_overseas_platform": "平台名称",
    "person_overseas_name": "账户名称",
    "person_overseas_url": "主页链接",
    "person_overseas_uid": "UID",
    "person_overseas_modal_title": "修改海外平台信息",
    "person_overseas_holder_platform": "请选择平台",
    "person_overseas_holder_name": "请输入海外账户名称",
    "person_overseas_holder_url": "请输入主页链接",
    "person_overseas_holder_uid": "请输入UID",
    "person_overseas_check_uid": "请输入数字",
    "person_privacy_info": "隐私信息",
    "person_passport_no": "护照号码",
    "person_passport_name": "护照姓名",
    "person_passport_expire": "护照有效日期",
    "person_passport_birth": "生日",
    "person_passport_address": "地址",
    "person_passport_modal_title": "修改护照信息",
    "person_passport_holder_no": "请输入护照号码",
    "person_passport_holder_name": "请输入护照姓名",
    "person_passport_holder_expire": "请选择护照有效日期",
    "person_passport_holder_birth": "请选择生日",
    "person_passport_holder_address": "请输入地址",
    "person_contract_no": "合同编号",
    "person_contract_cate": "分类",
    "person_contract_date": "合同起止日期",
    "person_contract_start_date": "生效日期",
    "person_contract_expire_date": "到期日期",
    "person_contract_operate": "是否独家运营",
    "person_contract_operate_true": "是",
    "person_contract_operate_false": "否",
    "person_contract_operate_ratio": "运营收益达人占比",
    "person_contract_ad": "是否独家商务",
    "person_contract_ad_ratio": "商务收益达人占比",
    "person_contract_remark": "合同相关备注",
    "person_contract_modal_title": "修改合同信息",
    "person_contract_remove": "是否解约",
    "person_contract_remove_date_true": "是",
    "person_contract_not_operating": "是否未运营",
    "person_contract_not_operating_true": "是",
    "person_contract_remove_date": "解约日期",
    "person_contract_not_operating_date": "未运营日期",
    "person_contract_true": "是",
    "person_contract_false": "否",
    "person_contract_holder_no": "请输入合同编号",
    "person_contract_holder_cate": "请选择合同分类",
    "person_contract_holder_operate": "请选择是否独家运营",
    "person_contract_holder_operate_ratio": "达人收益占比,请输入0~100的数字",
    "person_contract_holder_ad": "请选择是否独家商务",
    "person_contract_holder_ad_ratio": "达人收益占比,请输入0~100的数字",
    "person_contract_holder_remove_date": "请选择解约日期",
    "person_contract_holder_not_operating_date": "请选择未运营日期",
    "person_contract_holder_remark": "请输入合同相关备注",
    "person_contract_check_operate": "运营收益达人占比格式错误！",
    "person_contract_check_ad_ratio": "商务收益达人占比格式错误！",
    "person_bank_nation": "银行所在地",
    "person_bank_name": "银行名称",
    "person_bank_account": "银行账户",
    "person_bank_address": "银行英文地址",
    "person_bank_swift_code": "银行Swift Code",
    "person_bank_user_name": "姓名",
    "person_bank_id_card": "结算人身份证",
    "person_bank_modal_title": "修改银行信息",
    "person_bank_holder_nation": "请选择银行所在地",
    "person_bank_holder_name": "请输入银行名称",
    "person_bank_holder_account": "请输入银行账户",
    "person_bank_holder_address": "请输入银行英文地址",
    "person_bank_holder_swift_code": "请输入银行Swift Code",
    "person_bank_holder_user_name": "请输入姓名",
    "person_bank_holder_id_card": "请输入结算人身份证",
    "passport_dashboard_title": "签名的护照扫描件",
    "passport_handhold_title": "已签名的护照和一张自拍照",
    "person_level": "会员等级",
    "account_type": "账号分类",
    "bind_mcn": "所属MCN"
  },
  "feedback": {
    "feedback_type": "反馈类型",
    "type_all": "全部",
    "type_login": "登录问题",
    "type_app": "App问题",
    "type_suggest": "建议",
    "type_other": "其他问题"
  },
  "road": {
    "search_placeholder": "搜索uid/账号名/email",
    "creator_list_applied": "开通中",
    "creator_list_open_manage": "开通申请管理",
    "creator_list_all": "全部",
    "creator_status_cancelled": "已注销",
    "check_identity": "查看资料",
    "header_notice": "广告邮件通知",
    "header_notice_open": "同意",
    "header_notice_close": "不同意",
    "identity_info": "资料信息",
    "passport_info": "护照信息",
    "overseas_info": "海外平台信息",
    "account_info": "运营信息",
    "info_confirm": "信息确认",
    "account_avatar": "头像",
    "account_name": "账号名",
    "account_name_holder": "请输入账号名",
    "account_introduction": "简介",
    "account_introduction_holder": "请输入平台简介",
    "account_type": "分类",
    "account_type_holder": "请选择账号分类",
    "account_phone": "注册手机号",
    "account_phone_holder": "请输入注册手机号",
    "account_email": "电子邮箱地址",
    "account_email_holder": "请输入电子邮箱地址",
    "platform_case": "平台案例",
    "overseas_platform": "海外平台",
    "overseas_platform_holder": "请选择海外平台",
    "overseas_link": "海外平台链接",
    "overseas_link_holder": "请输入海外平台链接",
    "overseas_link_error": "海外平台链接不正确,需以http或https开头",
    "overseas_link_method": "了解如何获取海外平台链接",
    "overseas_link_method_tips": "在您的海外平台主页,复制当前页面链接",
    "overseas_method": "详细获取方法",
    "overseas_nickname": "海外平台昵称",
    "overseas_nickname_holder": "请输入海外平台昵称",
    "overseas_upload_proof": "上传证明材料",
    "overseas_main_page": "个人主页截图",
    "overseas_upload_main_page": "请上传手机端或者电脑端个人主页截图",
    "overseas_main_page_tips": "请按照以下示例图片截图",
    "overseas_backstage": "平台后台与本人合照",
    "overseas_upload_backstage": "请上传平台后台与本人合照",
    "overseas_backstage_tips": "请按照以下示例图片拍照",
    "personal_passport_name": "护照英文名",
    "personal_passport_name_holder": "请输入护照英文名",
    "personal_passport_number": "护照号",
    "personal_passport_number_holder": "请输入护照号",
    "personal_passport_date": "有效期限",
    "personal_passport_date_holder": "请选择护照有效期限",
    "personal_passport_date_error": "护照有效期不能少于6个月",
    "personal_upload_proof": "上传证明材料",
    "personal_upload_passport": "护照扫描件",
    "personal_upload_passport_holder": "请上传个人护照扫描件",
    "personal_upload_passport_tips": "请按照以下示例图片拍照",
    "personal_method": "详细获取方法",
    "personal_passport_hold": "手持护照照片",
    "personal_passport_hold_holder": "请上传手持护照照片",
    "personal_passport_hold_tips": "请按照以下示例图片拍照",
    "personal_passport_success": "护照信息更新成功",
    "personal_passport_fail": "护照信息更新失败",
    "personal_oversea_success": "海外平台信息更新成功",
    "personal_oversea_fail": "海外平台信息更新失败",
    "confirm_info": "确认信息",
    "submit_material_success": "平台资料提交成功",
    "submit_material_fail": "平台资料提交失败",
    "header_title": "开通申请管理",
    "header_no": "UID",
    "header_cate": "分类",
    "table_platform": "平台",
    "table_apply_date": "申请日期",
    "table_off_site_problem": "站外问题",
    "table_data_problem": "资料问题",
    "table_confirm_problem": "认证问题",
    "table_finished": "完毕",
    "table_finished_date": "完毕日期",
    "table_account_name": "账号名",
    "table_phone": "注册手机号",
    "table_email": "注册邮箱",
    "table_password": "注册密码",
    "table_action": "操作",
    "progress_not_start": "未开始",
    "progress_ongoing": "开通中",
    "progress_completed": "已完成",
    "progress_open_completed": "开通完成",
    "progress_open_failed": "开通失败",
    "progress_modal_title": "开通平台进度",
    "table_apply_date_remaining": "剩下{0}天",
    "table_apply_date_expire": "超时{0}天",
    "table_modal_steps_tips": "需要开启数据追踪功能才可以操作完毕。",
    "table_modal_steps_to_open": "去开启",
    "table_modal_password_holder": "请输入注册密码",
    "table_modal_note": "备注",
    "table_modal_note_holder": "请输入备注",
    "modal_message_err_status": "更新状态失败！",
    "modal_message_err_password": "更新密码失败！",
    "modal_message_err_tracking": "请先开启数据追踪！",
    "platform_account_name_error": "只能输入中文、英文和数字",
    "platform_is_stopped": "数据追踪",
    "platform_is_stopped_confirm": "重要操作！必须看完必读后，请再次确认",
    "platform_is_stopped_must_read": "必读",
    "modal_footer_previous": "开通进度",
    "modal_footer_previous_btn": "回到上一步",
    "all_platform_modal_tips_attention": "注意",
    "all_platform_modal_tips_link": "主页地址链接需正确填写。",
    "all_platform_modal_tips_mcn": "MCN必须已绑定。",
    "all_platform_modal_tips_nonsupport": "小红书平台暂不支持数据追踪。",
    "all_platform_modal_tips_times": "每次操作间隔6小时。",
    "all_platform_modal_tips_ failure": "等待5-10分钟可知道追踪结果，追踪失败时会自动关闭，需要找开发部询问处理。",
    "data_tracking_confirm_open_title": "确定开启数据追踪吗？",
    "data_tracking_confirm_close_title": "确定关闭数据追踪吗？",
    "data_tracking_countdown_label": "距下次可操作",
    "data_list_count": "共{}条数据"
  },
  "income": {
    "header_view_market_btn": "查看数据大盘",
    "header_export_settlement_btn": "导出结算信息",
    "header_tax_manage_btn": "扣税管理",
    "tax_manage_modal_title": "扣税管理",
    "tax_manage_tax_item": "扣税{0}",
    "tax_manage_name": "扣税名称",
    "tax_manage_rate": "扣税比率",
    "tax_manage_text": "扣税说明",
    "tax_manage_name_holder": "请输入扣税名称",
    "tax_manage_rate_holder": "请输入扣税比率",
    "tax_manage_text_holder": "请输入扣税说明",
    "export_settlement_file_name": "结算信息",
    "export_settlement_title_tips": "默认导出全部",
    "export_settlement_date_range": "起止日期",
    "export_settlement_status": "导出类型",
    "export_settlement_date_start_holder": "请选择开始日期",
    "export_settlement_date_end_holder": "请选择结束时间",
    "export_settlement_status_holder": "请选择导出类型",
    "creator_list_tabs_apply": "申请中",
    "creator_list_tabs_applied": "已申请",
    "creator_list_tabs_reviewed": "已审核",
    "creator_list_tabs_transfered": "已转账",
    "creator_list_tabs_rejected": "已驳回",
    "creator_list_tabs_failed": "已失败",
    "creator_list_tabs_all": "全部",
    "market_income_title": "收益大盘",
    "market_income_subtitle": "数据是前天为准",
    "market_income_all_income": "全平台累计收益",
    "market_income_all_opens": "全平台开通数",
    "market_income_all_opens_contrast": "平台开通数(比上个月)",
    "market_income_all_income_contrast": "全平台收益(比上个月)",
    "market_cash_title": "提现大盘",
    "market_cash_apply_money": "已申请金额(含税)",
    "market_cash_unfinished_money": "未提现金额(含税)",
    "market_cash_apply_user": "申请提现用户",
    "income_monetary_unit_label": "单位",
    "income_monetary_unit_cny": "元",
    "income_monetary_unit_krw": "韩币",
    "income_monetary_unit_won": "韩币",
    "income_monetary_unit_usd": "美元",
    "details_header_payee": "收款人",
    "details_table_apply_date": "申请日期",
    "details_table_settlement_period": "结算周期",
    "details_table_apply_amount": "申请金额(含税)",
    "details_table_tax_deduction": "扣税",
    "details_table_service_charge": "服务费",
    "details_table_transfer_status": "打款状态",
    "details_table_action": "操作",
    "details_table_transfer_status_ongoing": "处理中",
    "details_table_transfer_status_success": "打款成功",
    "details_table_transfer_status_failure": "打款失败",
    "details_table_action_to_withdrawal": "提现执行",
    "details_table_action_view_withdrawal": "查看提现",
    "details_bank_info": "银行信息",
    "details_bank_name": "银行名称",
    "details_bank_account_number": "银行账户",
    "details_bank_account_name": "账户名",
    "details_bank_real": "真实姓名",
    "details_bank_identity_number": "身份证号码",
    "details_bank_currency": "结算货币",
    "details_ip_ad_income": "IP广告收益(比上个月)",
    "details_total_amount": "总金额",
    "details_withdrawable_amount": "可提现金额",
    "details_cumulative_withdrawal amount": "累计提现金额",
    "withdrawal_modal_title_details": "提现详情",
    "withdrawal_modal_withdrawal_operation": "提现操作",
    "withdrawal_modal_apply_date": "申请日期",
    "withdrawal_modal_settlement_period": "结算周期",
    "withdrawal_modal_withdrawal_detail": "提现明细",
    "withdrawal_modal_platform_income": "平台收益",
    "withdrawal_modal_ad_income": "广告收益",
    "withdrawal_modal_tax1": "扣税1",
    "withdrawal_modal_tax2": "扣税2",
    "withdrawal_modal_tax3": "扣税3",
    "withdrawal_modal_service_charge": "服务费",
    "withdrawal_modal_after_tax_amount": "税后金额",
    "withdrawal_modal_payment_details": "打款明细",
    "withdrawal_modal_expected_date": "打款预计日期",
    "withdrawal_modal_exchange_rate": "打款汇率",
    "withdrawal_modal_exchange_amount": "汇率金额",
    "withdrawal_modal_personal_tax": "个人所得税3.3%",
    "withdrawal_modal_personal_paypal": "Paypal订单号",
    "withdrawal_modal_payment_amount": "打款金额",
    "withdrawal_modal_payment_status": "打款状态",
    "withdrawal_modal_cause_of_failure": "失败原因",
    "withdrawal_modal_currency": "结算货币",
    "withdrawal_modal_expected_date_holder": "请选择日期",
    "withdrawal_modal_exchange_rate_holder": "请输入打款汇率",
    "withdrawal_modal_exchange_amount_holder": "请输入汇率金额",
    "withdrawal_modal_personal_tax_holder": "请输入个人所得税",
    "withdrawal_modal_payment_amount_holder": "请输入打款金额",
    "withdrawal_modal_cause_of_failure_holder": "请输入失败原因",
    "withdrawal_modal_cause_failure_holder": "请选择失败原因",
    "withdrawal_modal_failure_option_error": "您的Paypal账户信息错误，未能付款成功。请检查后重新提交。",
    "withdrawal_modal_failure_option_other": "其他原因导致付款失败，请通过客服中心进行咨询处理。",
    "withdrawal_modal_currency_holder": "请选择结算货币",
    "withdrawal_modal_personal_paypal_holder": "请输入打款订单号"
  },
  "experimental": {
    "download_software": "下载登录软件",
    "software_terminal": "终端版",
    "software_interface": "界面版",
    "more_exciting": "更多精彩敬请期待~"
  },
  "notice": {
    "notice_send_notice": "发送站内通知",
    "notice_record": "站内通知记录",
    "notice_search_placeholder": "搜索UID",
    "notice_send_target": "发送对象",
    "notice_send_content_title": "原文发送内容",
    "notice_send_title": "标题",
    "notice_send_content": "内容",
    "notice_send_link": "链接",
    "notice_send_title_placeholder": "请输入标题",
    "notice_send_content_placeholder": "请输入内容",
    "notice_send_link_placeholder": "请输入链接",
    "notice_ai_translation_btn": "AI翻译",
    "notice_ai_translation_lang": "AI翻译语言",
    "notice_send_notification": "发送通知",
    "notice_send_user": "制定用户",
    "notice_record_page_title": "站内通知发送记录",
    "notice_record_search": "搜索",
    "notice_record_show": "显示",
    "notice_record_tip": "条",
    "notice_record_all": "共{0}条",
    "notice_record_refresh": "刷新",
    "notice_record_sender": "发送者",
    "notice_record_title": "标题",
    "notice_record_send_time": "发送时间",
    "notice_record_details": "详情",
    "notice_record_view_details": "查看详情",
    "notice_details_modal_title": "通知详情",
    "notice_receiver_id": "接收者ID",
    "notice_create_time": "创建时间",
    "notice_details_content": "通知内容",
    "notice_details_en": "英文版",
    "notice_details_zh": "中文版",
    "notice_details_ko": "韩文版",
    "notice_select_all": "全部",
    "notice_content_too_long": "{0}翻译后的通知内容字数不能超过1000字",
    "notice_translate_success": "AI翻译成功",
    "notice_translate_error": "AI翻译失败",
    "notice_send_success": "通知发送成功",
    "notice_send_error": "通知发送失败",
    "notice_select_user": "请选择接收者",
    "notice_search_time": "搜索发送时间：",
    "notice_start_time": "开始时间",
    "notice_end_time": "结束时间",
    "notice_search": "搜索{0}",
    "notice_reset": "重置",
    "notice_clear_all": "清除全部",
    "notice_word_limit": "字数不能超过1000字",
    "notice_send_confirm": "您确定要向以下用户发送通知吗？",
    "notice_selected_user": "已选择用户：",
    "notice_all_user": "全部用户",
    "notice_send_ok": "确认",
    "notice_send_cancel": "取消"
  },
  "translation": {
    "srt_translation": "AI 字幕",
    "srt_translation_success": "AI字幕已成功完成并正在下载中。请检查您的下载文件夹。",
    "srt_translation_error": "AI字幕失败，请重新上传文件",
    "srt_select_target_lang": "请选择目标语言",
    "srt_select_translation_model": "请选择翻译模型",
    "srt_select_translation_engine": "请选择翻译引擎",
    "srt_select_chatgpt_model": "请选择ChatGpt模型",
    "srt_upload_file": "请上传SRT/MP3文件",
    "srt_upload_btn": "上传文件",
    "srt_translate_btn": "AI字幕生成",
    "srt_target_lang_description": "上传的文件预计将被翻译成{0}。",
    "srt_subtitle": "AI翻译使用Whisper AI进行，它分析并翻译MP3和SRT文件，然后将其输出为SRT文件。",
    "srt_model_description_tiny": "非常快，但精度还可以",
    "srt_model_description_base": "速度快，精度高",
    "srt_model_description_small": "正常速度，精度高",
    "srt_model_description_medium": "速度慢但精度高",
    "srt_model_description_large": "非常慢，但是非常准确",
    "srt_translate_working": "AI正在翻译中。请勿关闭当前的浏览器窗口，工作将会被中断。",
    "srt_file_type_error": "文件类型不支持",
    "srt_prompt": "提示符",
    "srt_prompt_description": "提示符是用于开始任务的指示，它可以是问题、指令或句子。任务的准确性取决于提示符的清晰度。"
  }
};export default zh